
ai-filter-input,
filter-input {
    --padding: var(--space-xs);
    --font-size: var(--size-m);
    --background: var(--background-darker-color);
    --color: var(--foreground-color);

    height: calc(100% - var(--padding) * 2);
    display: inline-block;
    z-index: 5;

    font-size: var(--font-size);
    width: calc(100%);
    background: var(--background);
    border: none;
    color: var(--color);
    outline: none;
    border-radius: var(--border-radius-m);
    overflow: hidden;
    position: relative;
}

ai-filter-input input {
    background: var(--background);
    padding-left: var(--space-l);
}

ai-filter-input input:focus{
    outline: none;
}

ai-filter-input speach-button {
    position: absolute;
    left: 0;
    display: inline-block;
    top: var(--space-xxs);
    height: calc(var(--size-l) + var(--space-xxs));
}

[has~=mobile-layout] ai-filter-input {
   text-align: left;
}

[has~=mobile-layout] ai-filter-input input {
    width: calc(100% - var(--size-l));
}


[is=scroll-area] {
    -ms-overflow-style: none;
    scrollbar-width: none; 
    overflow-x: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
}

[is=scroll-area]::-webkit-scrollbar {
    display: none;
}

[is=filter-button] {
    display: inline;
    position: absolute;
    right: 0;
    height: calc(var(--size-l) + var(--space-xxs));
    top: var(--space-xxs);
    background-color: transparent;
    cursor: pointer;
    padding-right: var(--space-s);
}


[has~=mobile-layout] [is=filter-button] {
    top: var(--space-xs);
    display: none;
}

[has~=mobile-layout] ai-filter-input[now~=opened] [is=filter-button],
[has~=mobile-layout] filter-input[now~=opened] [is=filter-button]  {
    display: inline-block;
}


[is=filter-button][now~=playing]::before {
    animation-name: play-animation;
    animation-duration: 3s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
}

@keyframes play-animation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
}


[is=filter-button][now~=paused]::before {
    content: "\f04c";
    animation-name: paused-animation;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
}


[is=filter-button]:hover {
    background: var(--background-darker-color);
}

filter-input [is=scroll-area] {
    --padding: var(--space-xxs);
    padding-top: var(--padding);
    padding-bottom: var(--padding);
    margin-left: var(--padding);
    margin-right: calc(var(--space-l) + var(--space-xs));
}

[is=snippet-list] {
    padding:0;
    text-align: left;
    display: flex;
    height: 36px;
    transition: transform .1s ease;
}

[is=snippet-list] > input{
    padding:0;
    min-width: var(--size-xxxl);
    padding-left:var(--padding);
    transition: background-color 0.2s ease;
}

[is=snippet-list] input:hover,
[is=snippet-list] input:focus,
[is=snippet-list] input:active {
    border: none;
    outline: none;
}

[is=snippet-list] input:focus {
    background-color: var(--background-lighter-color);
}

[is=snippet-list] > * {
    margin-right: var(--space-xs);
}

[is=snippet-list] > *:last-child {
    margin-right: 0;
}

[is=filter-snippet] {
    padding: var(--space-xs);
    border-radius: var(--border-radius-m);
    vertical-align: middle;
    display: inline-flex;
    white-space: nowrap;
    text-overflow: ellipsis;
}



@keyframes insertSnippet {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

[is=filter-snippet] [is=snippet-text],
[is=filter-snippet] input,
[is=filter-snippet] select {
    font-size: var(--font-size-sm);
    font-family: var(--primary-font);
    background-color: transparent;
    width: auto;
    padding: 0;
    border: none;
    display: inline;
    color: var(--foreground-color);
}


[is=filter-snippet] select {
    background-image: linear-gradient(45deg,transparent 50%, var(--foreground-color) 50%),linear-gradient(135deg,var(--foreground-color) 50%,transparent 50%);
    background-position: calc(100% - 11px) calc(1em - 8px),calc(100% - 6px) calc(1em - 8px);
    border-radius: 0;
} 

[is=condition-selector] {
    min-width: var(--size-xl);
}

[is=filter-snippet] input:hover,
[is=filter-snippet] input:focus,
[is=filter-snippet] input:active,
[is=filter-snippet] input:checked
[is=filter-snippet] select:hover,
[is=filter-snippet] select:focus,
[is=filter-snippet] select:active,
[is=filter-snippet] select:checked { 
    border: none;
    border-bottom: 1px solid var(--foreground-soft-color);
    outline: none;
}

[is=filter-snippet] [is=remove-link] {
    line-height: var(--font-size-sm);
    font-size: var(--font-size-sm);
    padding-left: var(--space-xs);
    cursor: pointer;
}


[is=snippet-list] [is=filter-text-input]{
    background: transparent;
}

[is=snippet-list] [is=filter-text-input][now~=invalid] {
    border: 1px solid var(--error-color);

}

text-snippet {
    background-color: var(--color-scale-six);
}

contains-snippet {
    background-color: var(--color-scale-four);
}

category-snippet {
    background-color: var(--color-scale-thirteen);
}

url-snippet {
    background-color: var(--color-scale-eight);
}

location-snippet {
    background-color: var(--color-scale-three);
}

radius-snippet {
    background-color: var(--color-scale-two);
}

language-snippet {
    background-color: var(--color-scale-ten);
}

type-snippet {
    background-color: var(--color-scale-eleven);
}


exclude-snippet {
    background-color: var(--color-scale-one);
}


/*mobile layout */
@media (max-width: 620px) {
    [is=filter] ai-filter-input,
    [has~=mobile-layout] [is=filter] ai-filter-input,
    [is=filter] filter-input,
    [has~=mobile-layout] [is=filter] filter-input {
        overflow: visible;
        height: auto;
        z-index: 5;
    }

    [is=filter] ai-filter-input:not([now~=opened]),
    [has~=mobile-layout] [is=filter] ai-filter-input:not([now~=opened]),
    [is=filter] filter-input:not([now~=opened]),
    [has~=mobile-layout] [is=filter] filter-input:not([now~=opened]) {
        background: none; 
    }

    [is=filter] ai-filter-input[now~=opened],
    [has~=mobile-layout] [is=filter] ai-filter-input[now~=opened],
    [is=filter] filter-input[now~=opened],
    [has~=mobile-layout] [is=filter] filter-input[now~=opened] {
        overflow: visible;
        padding: var(--space-xs);
        top: calc(var(--size-l) + var(--size-s) + var(--top-gap));
        width: calc(100vw - (2 * var(--space-s)));
        position: absolute;
        left: 0;
        margin: var(--space-s);
    }

    [is=filter-button],
    [has~=mobile-layout] [is=filter-button] {
        padding-right: var(--space-xs);
    }

    [is=filter] ai-filter-input[now~=opened] [is=filter-button],
    [has~=mobile-layout] [is=filter] ai-filter-input[now~=opened] [is=filter-button],
    [is=filter] filter-input[now~=opened] [is=filter-button],
    [has~=mobile-layout] [is=filter] filter-input[now~=opened] [is=filter-button],
    [has~=mobile-layout] [is=filter] ai-filter-input[now~=opened] speach-button button {
        height: calc(var(--size-xl) - var(--space-xs));
    }

    [is=filter]  [is=filter-button]:hover,
    [has~=mobile-layout] [is=filter]  [is=filter-button]:hover {
        background: none;
    }

    [has~=mobile-layout] [is=filter] ai-filter-input[now~=opened] speach-button button {
        top: var(--space-xxs);
        position: absolute;
        
    }

    [is=filter] ai-filter-input [is=filter-button][has*=-icon]::before,
    [has~=mobile-layout] [is=filter] ai-filter-input [is=filter-button][has*=-icon]::before,
    [is=filter] filter-input [is=filter-button][has*=-icon]::before,
    [has~=mobile-layout] [is=filter] filter-input [is=filter-button][has*=-icon]::before,
    [has~=mobile-layout] [is=filter] ai-filter-input[now~=opened] speach-button button::before {
        --font-size: calc(var(--size-m) + var(--size-xs))
    }
}



filter-input[has~=input-layout] [is=scroll-area] {
    margin-right: var(--padding);
}