[is=fleebs-spinner]{
    --circle-size: 20px;
    position: relative;
}

[is=fleebs-spinner][has~=overlay-layout]{
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
}

[is=circle] {
    height: var(--circle-size);
    width: var(--circle-size);
    border-radius: 50%;
    transform: scale(1) translate(var(--endX), var(--endY));
    transition: all 0.5s ease;
    position: absolute;
    opacity: 0.8;
}


[is=circle][now~=moving] {
    opacity: 0;
}

[is=circle][now~=started] {
    transform: scale(0.1) translate(calc(var(--x) * 1), calc(var(--y) * 1));
    opacity: 0.2;
}


[is=circle][now~=ended] {
    transform: scale(0.2) translate(calc(var(--x) * 2), calc(var(--y) * 2));
    opacity: 0;
}