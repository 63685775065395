[is=event-date] {
    font-size: var(--font-size-sm);
    display: block;
    padding-top: var(--space-xs);
}

[is=event-date]::before {
    padding-right: var(--space-xs);
}

[is=event-entry] {
    font-size: var(--font-size-s);
    padding: var(--space-s);
    display: grid;
    gap: var(--space-m);
    grid-template-columns: 1fr var(--size-xxxl);
    grid-template-rows: 1fr var(--size-l);
    position: relative;
}

[has~=mobile-layout] [is=event-entry] {
    display: block;
}

[is=event-entry]:nth-child(1){
    padding-top: 0;
}

[is=event-entry][has~=layout-with-image] {
    grid-template-columns: 150px 1fr var(--size-xxxl);
}

[is=event-entry]:nth-child(odd){
    background-color: var(--background-lighter-color);
}

[is=event-entry] [is=event-date] {
    font-size: var(--font-size-s);
    font-weight: bold;
    padding-top: 0;
}

[is=event-entry] [is=event-title],
[is=event-entry] [is=event-description]{
    padding-bottom: var(--space-s);
}

[is=event-entry] [is=event-title]{
    font-size: var(--font-size-sm);
}

[is=event-entry] [is=event-date]{
    padding-bottom: var(--space-xxs)
}

[is=event-entry] [is=event-location-title] {
    padding: 0;
    margin: 0;
    padding-bottom: var(--space-xs);
    font-size: var(--font-size-s);
}

[is=event-entry] [is=event-action] {
    grid-row: 2;
    grid-column: 1 / 4;
    text-align: right;
}

[has~=mobile-layout] [is=event-entry] [is=event-action] {
    margin-top: var(--space-m);
}


[is=event-entry] [is=event-action] [is=icon-button]{
    margin-bottom: var(--space-xs);
    padding: var(--space-xxs) var(--space-xs);
    border-radius: var(--size-xs);
    font-size: var(--font-size-s);
    display: inline-block;
    font-family: inherit;
    margin-left: var(--space-s);
}


[is=event-entry] [is=event-action] [is=icon-button]::before {
    padding-right: var(--space-xs);
}


[is=event-entry] [is=outdated-teaser]{
    position: absolute;
    font-weight: 700;
    background: var(--error-color);
    color: white;
    font-size: var(--font-size-sm);
    padding: 5px 10px;
    border-radius: 15px;
    top: var(--space-xl);
    left: var(--space-l);
}

