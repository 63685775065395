real-estate-details [is=real-estate-part]{
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    padding-bottom: var(--space-m);
}

real-estate-details [is=real-estate-part]:last-child{
    padding-bottom: 0;
}

real-estate-details [is=real-estate-part] [is=property-list] {
    padding: var(--space-m) 0 0 var(--space-m);
}
