vertical-scroller {
    display: block;
    white-space: nowrap;
    overflow: hidden;
}

vertical-scroller [is=scroll-area]   {
    display: block;
    white-space: nowrap;
    transition: transform 0.5s ease-out;
}

vertical-scroller [is=arrow-link-left],
vertical-scroller [is=arrow-link-right]{
    position: absolute;
    z-index: 2;
   /* background: var(--background-darker-color); */
    background-image: linear-gradient(to right,  transparent 0%,  var(--background-color) 20%);
    top: var(--size-xxs);
    padding: var(--space-xs);
    cursor: pointer;
}

vertical-scroller [is=arrow-link-left]::before,
vertical-scroller [is=arrow-link-right]::before {
    font-size: calc(var(--size-m) + var(--size-xs));
}

vertical-scroller [is=arrow-link-left]{
    left: var(--space-l);
    background-image: linear-gradient(to right, var(--background-color) 60%,  transparent 100%);
}

vertical-scroller [is=arrow-link-right]{
    right: var(--space-l);
    background-image: linear-gradient(to right,  transparent 0%,  var(--background-color) 40%);
}

[has~=mobile-layout] vertical-scroller [is=arrow-link-left]{
    left: 0;
}

[has~=mobile-layout] vertical-scroller [is=arrow-link-right]{
    right: 0;
}

[has~=mobile-layout] vertical-scroller [is=arrow-link-left]::before,
[has~=mobile-layout] vertical-scroller [is=arrow-link-right]::before {
    font-size: calc(var(--size-m) + var(--size-xxs))
}

