
[is=fleeb-teaser] immo-fleebs-teaser[is=content][has~=offer] {
    grid-template-rows: 60% calc(40% - 30px ) 30px;
}


[is=fleeb-teaser][has~=wide-teaser-layout] immo-fleebs-teaser[is=content][has~=offer] {
    grid-template-rows: 1fr 30px;
}


[has~=mobile-layout] [is=fleeb-teaser]  immo-fleebs-teaser[is=content][has~=offer]{
    grid-template-rows: 50% calc(50% - 26px) 26px;
}

[has~=mobile-layout] [is=fleeb-teaser][has~=wide-teaser-layout] immo-fleebs-teaser[is=content][has~=offer] {
    grid-template-rows: 1fr 26px;
}



[has~=mobile-layout] [is=fleeb-teaser] immo-fleebs-teaser [is=offer]{
    top: calc(50% - var(--font-size-s));
}

[has~=mobile-layout] [is=fleeb-teaser][has~=wide-teaser-layout] immo-fleebs-teaser [is=offer] {
    top: var(--font-size-s);
}

[is=fleeb-teaser][has~=text-layout] immo-fleebs-teaser[is=content][has~=offer] {
    grid-template-rows: calc(100% - 30px) 30px;
    padding-top: var(--space-l);
}


[is=fleeb-teaser] immo-fleebs-teaser[is=content][has~=offer] h2 {
      /*
    padding-top: var(--space-m);
    padding-bottom: var(--space-s);
  
    max-height: calc((var(--line-height) * var(--lines)) + var(--space-m));
    */
}

[is=fleeb-teaser][has~=wide-teaser-layout] immo-fleebs-teaser[is=content][has~=offer] h2 {

  /*  max-height: calc(var(--line-height) + var(--space-m));
    overflow: hidden;
    text-wrap: wrap;*/
}




[has~=mobile-layout]  [is=fleeb-teaser] immo-fleebs-teaser[is=content] a,
[is=fleeb-teaser] immo-fleebs-teaser[is=content] a {
    padding-top: var(--space-xxs);
}

/*
[is=fleeb-teaser] [is=immo-properties]{
    display: flex;
    padding-left: var(--space-s);
    padding-right: var(--space-s);
    gap: var(--space-s);
    margin-top: var(--space-xxs);
}*/

 /* 

[has~=mobile-layout]  [is=fleeb-teaser] immo-fleebs-teaser[is=content][has~=offer] h2 {
    padding: var(--space-s);
    padding-bottom: var(--space-s);
  max-height: calc((var(--line-height) * var(--lines)) + var(--space-s));
}

[has~=mobile-layout] [is=fleeb-teaser][has~=wide-teaser-layout] immo-fleebs-teaser[is=content][has~=offer] h2 {
    max-height: calc(var(--line-height) + var(--space-s));
    overflow: hidden;
    text-wrap: wrap;
}*/
