[is=user-symbol] {
    font-size: var(--font-size-sm);
    padding: var(--space-xxs);
    border-radius: 50%;
    color: var(--forground-color);
    --size: calc(var(--size-l) + var(--size-xs));
    height: var(--size);
    width: var(--size);
    display: inline-flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    cursor: pointer;
}