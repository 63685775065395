
/* Wide Teaser Layout */ 
[is=fleeb-teaser][has~=wide-teaser-layout] {

    & picture-loader,
    & [is=teaser-icon] {
        position: absolute;
        height: 100%;
        width: 100%;
    }

    &  [is=teaser-properties] {
        margin-bottom: var(--space-xxs);
        z-index: 2;
        position: relative;
    }


}




[is=fleeb-teaser][has~=wide-teaser-layout] picture-loader::after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    bottom:0;
    background: linear-gradient(#15151500 45% ,#151515ee 71%);
}

/*
[has~=mobile-layout] [is=fleeb-teaser][has~=wide-teaser-layout] picture-loader::after{
    height: 80%;
}
    */


[is=fleeb-teaser][has~=wide-teaser-layout] h2 {
    z-index: 2;
    font-size: calc(var(--font-size-l) - var(--font-size-xxs));
    --line-height: calc(var(--font-size-l));
    padding-bottom: 0;
    align-self: end;
    margin-bottom: var(--space-xs);
}


[is=fleeb-teaser][has~=wide-teaser-layout] [is=text-row] {
    align-self: end;
}


[has~=mobile-layout] [is=fleeb-teaser][has~=wide-teaser-layout] [is=content] h2 {
    font-size: calc(var(--font-size-m) -  var(--font-size-xxs));
    --line-height: calc(var(--font-size-m));
    padding-bottom: 0;
    margin-bottom: 0;
}

[is=fleeb-teaser][has~=wide-teaser-layout] [is=content][has~=offer] h2 {
    padding-top: 0;
}


[is=fleeb-teaser][has~=wide-teaser-layout] a {
    z-index: 2;
}


[is=fleeb-teaser][has~=wide-teaser-layout] [is=content] {
    grid-template-rows: 1fr 30px;
    padding-top: calc(var(--space-xxl) + var(--space-l));
}

[has~=mobile-layout] [is=fleeb-teaser][has~=wide-teaser-layout] [is=content] {

    padding-top: calc(var(--space-xxl));
}


[has~=mobile-layout] [is=fleeb-teaser][has~=wide-teaser-layout] [is=content] {
   grid-template-rows: 1fr 26px;
}
[has~=mobile-layout] [is=fleeb-teaser][has~=wide-teaser-layout] [is=link] {
    padding-top: var(--space-xxs);
}


[is=fleeb-teaser][has~=wide-teaser-layout] [is=offer] + [is=text-row] {
    display: grid;
    grid-template-rows: 1fr 30px;
    overflow: hidden;
    max-height: 100%;
}

[has~=mobile-layout] [is=fleeb-teaser] [is=content][has~=offer] [is=text-row] {
    margin-top: 0;
}

