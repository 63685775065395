suggest-filter-bar {
    display: block;
    top: calc(var(--size-xl) + var(--size-s) + var(--top-gap));
    width: 100%;
    padding-top: var(--space-xs);
    z-index: 2;
    overflow: auto;
    display: block;
    text-align: center;
    max-height: calc(50vh - var(--top-gap));

}


suggest-filter-bar [is=tag] {
    padding: var(--space-xs) var(--space-s);
    filter: grayscale(1);
    cursor: pointer;
    margin-bottom: var(--space-s);
}

suggest-filter-bar [is=tag][now~=selected] {
    filter: none;
}

[has~=mobile-layout] suggest-filter-bar {
    top: calc(var(--size-l) + var(--size-s) + var(--top-gap));
    padding: var(--space-xs) var(--space-s);
}
