
[is=content][has~=sidebar-layout]{
    display: grid;
    grid-template-columns: clamp(220px, 20vw, 20vw) 1fr;
    justify-items: left;
    grid-gap:var(--space-m);
}

[is=content][has~=filter-layout]{
    display: block;
}

[is=default-page]{
    max-width: var(--max-content-width);
    margin-top: calc(var(--space-xxl) + var(--top-gap));

}

[is=default-page] h1{
    margin-bottom: var(--space-l);
}

[is=default-page] h2{
    margin-bottom: var(--space-m);
}

[is=default-page] h3{
    margin-bottom: var(--space-s);
}


[is=default-page] [is=user-group]{
    display: none;
}

[is=default-page] h3, [is=default-page] h4, [is=default-page] ul{
    margin-bottom: var(--space-m);
}

[is=default-page] li{
    list-style-type: square;
    list-style-position: inside;
}


[is=default-page][has~=centered-content] {
    display: grid;
    align-content: center;
    align-items: center;
    justify-content: center;
}

[has~=mobile-layout] [is=default-page][has~=centered-layout] {
    width: clamp(100px, calc(95vw), calc(var(--max-content-width)));
}


[is=sub-title] {
    margin-bottom: var(--space-l);
}


