fleebs-messages {
    position: relative;
    z-index: 2;
}

[is=fleebs-message-container] {
    display: block;
    margin-top: calc(var(--top-gap) + var(--space-xl));
}

[is=fleebs-message]{
    padding: var(--space-s) var(--space-l);
    border: 5px var(--error-color) solid;
    color: var(--error-color);
    text-align: center;
    margin:  var(--space-s) var(--space-xxl);
   /* animation-name: guruBorderAnimation;
    animation-duration: 0.5s; */
}

[has~=mobile-layout] [is=fleebs-message] {
    margin:  var(--space-s) var(--space-l);
}

[is=fleebs-message] * {
    font-family: "Lucida Console", "Courier New", monospace;
    font-weight: bold;
   
    transform: scale(1, 1.5);
    
}

[is=fleebs-message] a {
    margin-left: var(--space-s);
    cursor: pointer;
}


[is=fleebs-message] [is=first-message-line] {
    margin-bottom: var(--space-m);
}

[is=fleebs-message] [is=first-message-line],
[is=fleebs-message] [is=error-code] {
    display: block;
}


[is=message] {
    padding: var(--space-s) var(--space-l);
    background-color: var(--grey-dark-color);
    border-radius: var(--border-radius-s);
    line-height: var(--font-size-l);
}

[is=message][has~=error] {
    border: 1px solid var(--error-color);
    color: var(--error-color);
}


@keyframes guruBorderAnimation {
    50% {
        border-color: transparent;
    }
  }