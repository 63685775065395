[is=fleeb-teaser][has~=book-type] picture{
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: var(--background-darker-color);
}


[is=fleeb-teaser][has~=book-type] [is=content] {
    grid-template-rows: 60% calc(40% - 30px) 30px;
}

[is=fleeb-teaser][has~=book-type][has~=wide-teaser-layout] [is=content] {
    grid-template-rows: 1fr 30px;
}

 /*
[is=fleeb-teaser][has~=book-type] [is=content][has~=offer] h2 {
    padding-top: var(--space-m);
    padding-bottom: var(--space-m);
    max-height: calc((var(--line-height) * ( var(--lines) + 1)));
}


[has~=mobile-layout] [is=fleeb-teaser][has~=book-type] [is=content][has~=offer] h2 {
    padding-top: var(--space-s);
    padding-bottom: 0;
}*/
