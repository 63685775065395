
fleeb-row-teaser {
    display: block;
    position: relative;
}



fleeb-row-teaser [is=row-selection-checkbox]{
    position: absolute;
    top: calc(var(--space-xs) * -1);
    left: calc(var(--space-xs) * -1);
    background: var(--foreground-color);
    border-color: var(--background-darker-color);
}

fleeb-row-teaser [is=row-selection-checkbox]:before {
    box-shadow: inset var(--size-m) var(--size-m) var(--background-color);
}

[is=fleebs-row-teaser] {
    --teaser-image-size: var(--size-xxl);
    --share-area-width: 20px;
    display: grid;
    grid-template-columns: 1fr calc(var( --teaser-image-size) + (2 *  var(--space-s))) var(--share-area-width);
    background-color: var(--grey-dark-color);
    border-radius: 10px;
    overflow: hidden;
}

[is=fleebs-row-teaser]:not(:has([is=teaser-image])){
    grid-template-columns: 1fr var(--share-area-width);
}


[is=fleebs-row-teaser] [is=teaser-image] {
    object-fit: cover;
    height: var(--teaser-image-size);
    width: var(--teaser-image-size) ;
    margin: var(--space-s);
    border-radius: 10px;
}


[is=fleebs-row-teaser] h2 {
    font-size: var(--font-size-sm);
}

[is=fleebs-row-teaser] img {
    max-width: 100%;
    height: 100%;
}


[is=fleebs-row-teaser] [is=fleebs-teaser-link-section] {
    display: grid;
    grid-template-columns: 21px 1fr;
}

[is=fleebs-row-teaser] [is=fleebs-teaser-link-section] [is=provider-icon]{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: var(--space-s);
}

[is=fleebs-row-teaser] [is=fleebs-row-teaser-content] {
    overflow: hidden;
    display: grid;
    padding: var(--space-s) var(--space-s) var(--space-s) var(--space-m);
    grid-template-rows: 1fr min-content var(--font-size-m);
}


[is=fleebs-row-teaser] [is=fleebs-teaser-description] {
    padding: var(--space-xs) 0 var(--space-m) 0;
    font-size: var(--font-size-s);
}



[is=fleebs-row-teaser] [is=link] {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: var(--font-size-s);
    align-self: center;
    padding-left: var(--space-xs);
}


[has~=mobile-layout] [is=fleebs-row-teaser] h3{
    font-size: var(--font-size-sm);
}


[is=fleebs-row-teaser] fleeb-share-area {
    padding-top: var(--space-xxs);
}

[is=fleebs-row-teaser] [is=share-area] {
    display: inline;
    text-align: center;
}

[is=fleebs-row-teaser] [is=share-area] [has*=-icon]::before {
    --font-size: var(--font-size-sm);
}
