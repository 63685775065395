[has~=mobile-layout] filter-button [is=filter-button],
filter-button [is=filter-button]{
    --size: calc(var(--size-xl) + var(--space-xxs));

    display: grid;
    height: var(--size);
    width:  var(--size);
    left: calc(50% - (var(--size) / 2));
    z-index: 5;
    background-color: var(--call-to-action-color);
    border-radius: 50%;
    box-shadow: var(--default-box-shadow);

    margin-top: calc(var(--space-s) * -1);
    padding: 0;
}

[has~=mobile-layout] filter-button [is=filter-button] {
    --size: calc(var(--size-xl) + var(--space-s));
    top: calc(var(--space-xxs)* -1);
}

[has~=mobile-layout] filter-button [is=filter-button]::before,
filter-button [is=filter-button]::before {
    padding: 0;
    font-size: var(--font-size-l);
}


filter-button [is=filter-button][has~=fleebs-icon]::before {
    background-image: url(/assets/images/logo_only.svg);
    content: "";
    display: inline-block;
    height: var(--space-l);
    width: var(--space-l);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

