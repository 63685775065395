fleebs-history [is=table]{
    overflow: hidden;
    border-radius: var(--size-s);
    box-shadow: var(--unshifted-thin-box-shadow);
    font-size: var(--font-size-sm);
}

fleebs-history [is=table-header], [is=table-body]{
    border: none;
}

fleebs-history [is=table-row]{
    display: grid;
    grid-template-columns: auto auto 1fr;
    width: 100%;
}

fleebs-history [is=table-cell]{
    padding: var(--space-s);
    border-left: 1px solid var(--background-lighter-color);
    white-space: nowrap;
    display: flex;
    align-self: center;
    /* align-items: center; */
    justify-content: center;
}

fleebs-history [has~=thumbnail]{
    padding: var(--space-xs);
    width: 8vh;
    height: 6vh;
}

/*
fleebs-history picture{
    background-position: center;
    background-size: cover;
    width: 7vh;
    height: 4vh;
    display: block;
}
*/

fleebs-history [is=table-cell]:first-child{
    border-left: none;
}

fleebs-history [is=table-header]{
    background-color: var(--background-darker-color);
}

fleebs-history [is=table-row]:hover,
fleebs-history [is=table-row]:nth-child(even):hover{
    background-color: var(--background-darker-color);
}

fleebs-history [is=table-row]:nth-child(even){
    background-color: var(--grey-dark-color);
}

fleebs-history [has~=date]{
    white-space: nowrap;
    min-width: 9rem;
    justify-content: flex-start;
}

fleebs-history [has~=link]{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: underline;
    justify-content: flex-start;
}

fleebs-history [is=table-header] [has~=link],
fleebs-history [is=table-header] [has~=thumbnail]{
    display: block;
    text-decoration: none;
}

fleebs-history [is=table-header] [has~=thumbnail]{
    height: auto;
    text-align: center;
}

fleebs-history [has~=link] a{
    overflow: hidden;
    text-overflow: ellipsis;
}