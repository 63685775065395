

hint-container {
    position: absolute;
    max-height: max-content;
    left: 0;
    z-index: 4;
    background: var(--background-darker-color);

}

hint-container suggest-filter-bar {
    max-height: 400px;
    height: 400px;
}
