[is=teaser-icon] {
    background: var(--background-darker-color);
}

[is=teaser-icon]::before {
    font-size: var(--font-size-xxxl);
    display: block;
    height: 100%;
    text-align: center;
    line-height: 100%;
    padding-top: 10%;
    opacity: 0.5;
}

[has~=mobile-layout] [is=teaser-icon]::before{
    font-size: var(--font-size-xxl);
}


