

[has~=markdown-text] {

}

[has~=markdown-text] h1 {
    margin-bottom: var(--space-m);
}

[has~=markdown-text] h2 {
    margin-bottom: var(--space-s);
}

[has~=markdown-text] h3 {
    margin-bottom: var(--space-s);
}

[has~=markdown-text] h4 {
    margin-bottom: var(--space-s);
}

[has~=markdown-text] a {
    text-decoration: underline;
    cursor: pointer;
}

[has~=markdown-text] [is=list] {
    padding: 0 0 0 var(--space-m);
}

[has~=markdown-text] [is=list] [is=list] {
    margin: 0;
    padding-top: var(--space-s);
}

[has~=markdown-text] [is=list] li{
    list-style-position: outside;
}

[has~=markdown-text] table{
    margin-bottom: var(--space-m);
    background-color: var(--grey-dark-color);
    border-collapse: collapse;
}

[has~=markdown-text] table tr:nth-of-type(even){
    background-color: var(--background-lighter-color);
}

[has~=markdown-text] table thead{
    background-color: var(--background-darker-color);
}

[has~=markdown-text] table th,
[has~=markdown-text] table td {
    padding: var(--space-xs);
}


[has~=mobile-layout] [has~=markdown-text] h1 {
    font-size: var(--font-size-ml);
}

[has~=mobile-layout] [has~=markdown-text] h2 {
    font-size: var(--font-size-m);
}

[has~=mobile-layout] [has~=markdown-text] h3 {
    font-size: var(--font-size-sm);
}

[has~=mobile-layout] [has~=markdown-text] p,
[has~=mobile-layout] [has~=markdown-text] a,
[has~=mobile-layout] [has~=markdown-text] li,
[has~=mobile-layout] [has~=markdown-text] table {
    font-size: var(--font-size-s);
}




