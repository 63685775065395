

[has~=background-color-one] {
    background-color:var(--color-scale-one);
}

[has~=background-color-two] {
    background-color:var(--color-scale-two);
}

[has~=background-color-three] {
    background-color:var(--color-scale-three);
}

[has~=background-color-four] {
    background-color:var(--color-scale-four);
}

[has~=background-color-five] {
    background-color:var(--color-scale-five);
}