[is=suggest-container] {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 20%;
}

[is=suggest-topic]{
    text-align: center;
    padding: var(--space-xxl) 0 var(--space-s) 0;
    font-size: var(--size-sm);
    display: block;
    margin: 0;
    
    width: 100%;
}

[has~=mobile-layout] [is=suggest-topic]{
    padding: var(--space-l) 0 var(--space-s) 0;
}

body:not([has~=landing-page-layout]) [is=suggest-topic],
body:not([has~=landing-page-layout]) [is=suggest-container]{
    display: none;
}

[is=suggest] {
    border-radius: 20px;
    display: inline-block;
    color: white;
    text-decoration: none;

    padding: var(--space-s);

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    margin: var(--space-xs);
}

[has~=mobile-layout] [is=suggest] {
    padding: var(--space-xs);
    margin: var(--space-xxs);
    font-size: var(--font-size-s);
}

[has~=mobile-layout] [is=suggest] {
    padding: var(--space-xs);
}

[is=suggest][has~=size-s]{
    --size: calc(var(--size) * 1);
}

[is=suggest][has~=size-m]{
    --size: calc(var(--size) * 3);
}

[is=suggest][has~=size-l]{
    --size: calc(var(--size) * 5);
}

[is=suggest][has~=size-xl]{
    --size: calc(var(--size) * 10);
}

[is=suggest][has~=size-xxl]{
    --size: calc(var(--size) * 15);
}


