button {
    background: none;
    border: none;
    cursor: pointer;
}


[is=button],
[is$=-button] {
    --background: var(--call-to-action-color);
    --color: var(--forground-color);

    background: var(--background);
    color: var(--color);
    text-align: center;
    display: grid;
    border: none;
    vertical-align: middle;
    outline: none;
    transition: background ease-in-out 0.3s;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0;

    &[has~=no-background]{
        background: none;
    }
}

[is=button][now~=loading]::after,
[is$=-button][now~=loading]::after{
    content: '';
    display: inline-block;
    height: var(--size-xs);
    margin-left: var(--space-xs);
    width: var(--size-l);
    background-image: url(../assets/images/three-dots.svg);
    background-repeat: no-repeat;
    background-size: 100%
}

[is=button]:hover,
[is$=-button]:hover {
    --background: var(--call-to-action-dark-color);
}




[is=rounded-button],
[is$=-rounded-button] {
    --size: calc(var(--size-l) + var(--size-xs));
    border-radius: 50%;
    height: var(--size);
    width: var(--size);
    line-height: var(--size);
    vertical-align: middle;
    box-shadow: 2px 2px 2px black;
}


[is=rounded-button][has~=medium-size],
[is$=-rounded-button][has~=medium-size]{
    --size: calc(var(--size-l));
}

[is=rounded-button][has~=small-size],
[is$=-rounded-button][has~=small-size]{
    --size: calc(var(--size-ml));
}

[is=rounded-button][has~=glow-shadow],
[is$=-rounded-button][has~=glow-shadow]{
    box-shadow: 0 0 3px #000;
}

[is=rounded-button][has~=glow-shadow],
[is$=-rounded-button][has~=glow-shadow]{
    box-shadow: 0 0 3px #000;
}

[is=rounded-button][has~=dark-style],
[is$=-rounded-button][has~=dark-style]{
    background-color: var(--background-color);
    border: 1px solid var(--foreground-soft-color);
}


[is=fleebs-button]{
    display: inline-block;
    z-index: 1;
    position: relative;
}

[is=ghost-button],
[is$=-ghost-button]{
    padding: var(--space-xs);
    border: 1px solid var(--foreground-color);
    background: transparent;
}


[is=rounded-ghost-button],
[is$=-rounded-ghost-button]{
    border-radius: 50%;
}


[has~=rounded-shapes]{
    --size: calc(var(--size-l) + var(--size-xs));
    border-radius: 50%;
    height: var(--size);
    width: var(--size);
    line-height: var(--size);
    vertical-align: middle;
    box-shadow: 2px 2px 2px black;
}

/*
[has~=fleebs-icon]::before{
    --background-size: 65%;
    --margin: calc((100% - var(--background-size)) / 2);
    transform: translate(0, -2px);
    content: ' ';
    display: inline-block;
    background-image:url(/assets/images/fleebs-icon.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle;
    transform: rotate(180deg);
    height: var(--background-size);
    width: var(--background-size);
}*/

[has~=mobile-layout] [has~=fleebs-icon]::before{
    transform: rotate(0);
}

[is=link-button] {
    padding: var(--space-xs) var(--space-s);
    overflow: hidden;
    display: inline-block;
    width: auto;
    font-size: var(--font-size-sm);
    background: none;

    &::before {
        padding-right: var(--space-xs);
        vertical-align: bottom;
    }
}


[has~=mobile-layout] [is=link-button] {
    font-size: var(--size-s);

    &::before{
        vertical-align: top;
    }
}

button:disabled, button:disabled::before{
   /* color: var(--grey-light-color);*/
}

[has~=secondary-color]{
    background: var(--grey-darker-color);
}

[is=form-button] {
    padding: var(--space-xs) var(--space-s);
    overflow: hidden;
    display: inline-block;
    font-size: var(--font-size-sm);
    border-radius: var(--size-s);
}

[is=form-button]::before {
    padding-right: var(--space-s);
}


[is=likes-navigation-button][now~=fleeb-added] {
    animation-name: fleeb-added;
    animation-duration: 0.5s;
   /* animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);*/
}

[is=icon-button],
[is$=-icon-button]{
    padding: var(--space-xxs) var(--space-s);
    font-size: var(--font-size-s);
    display: inline-block;
    font-family: inherit;
    border-radius: var(--size-s);
}

[is=icon-button]::before,
[is$=-icon-button]::before {
    vertical-align: sub;
    padding-right: var(--space-xxs);
}

[is=link-icon-button],
[is$=-link-icon-button]{
    background: none;
}


@keyframes fleeb-added {
    0% {
        transform: matrix(1.00, 0.00,0.00,1.00,0,0);
    }

    30% {
        transform: matrix(1.50, 0.10, 0.00, 1.50, 0, 0);
    }

    50% {
        transform: matrix(1.30, 0.00,0.00,1.30,0,0);
    }

    80% {
        transform: matrix(1.50, -0.10, 0.00, 1.50, 0, 0);
    }

    100% {
        transform: matrix(1.00,0.00,0.00,1.00,0,0);
    }
}


[is=button][has~=full-size],
[is$=-button][has~=full-size] {
    width: 100%;
    display: inline-block;

}

[is=button][has~=soft-color],
[is$=-button][has~=soft-color]{
    background-color: var(--call-to-action-dark-color);
}


[is=big-logo-button]{
    background-image: url(/assets/images/logo_only.svg);
    width: 100%;
    height: var(--space-xxl);
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    margin-bottom: var(--space-xl);
    opacity: 0.9;
}


[has~=mobile-layout] [is=fleeb-teaser] [is=content] [is=play-button],
[is=fleeb-teaser] [is=content] [is=play-button],
[is=play-button] {
    --size: calc(var(--size-xl) + var(--size-s));

    width: var(--size);
    height: var(--size);
    padding: var(--space-s);

    display: grid;
    grid-template-columns: 1fr;

    align-items: center;
    justify-content: center;
    justify-items: center;

    border-radius: 50%;

    opacity: 0.8;
    transition: transform 0.3s ease-in-out;
    
}

[has~=mobile-layout] [is=fleeb-teaser] [is=content] [is=play-button]:hover,
[is=fleeb-teaser] [is=content] [is=play-button]:hover,
[is=play-button]:hover {
    transform: scale(1.3);
}


[is=play-button]:before {
    font-size: var(--font-size-l);
}


speach-button {
    display: inline-block;
    position: relative;
}

speach-button[now~=recording] button{
    z-index: 2;
    position: relative;
}




speach-button[now~=recording]::before,
speach-button[now~=recording]::after {
    content: ' ';
    z-index: 1;
    background: var(--call-to-action-color);
    animation: recoding-animation 2s infinite;
    
    display: inline-block;
    height: var(--size-s);
    width: var(--size-s);
    position: absolute;
    left: var(--space-s);
    top: var(--space-xxs);
    border-radius: 50%;
    transform: scale(0);
}

speach-button[now~=recording]::before {
    animation-delay: 0.5s;
}


@keyframes recoding-animation {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}