[is=teaser-list] {
    padding-left: 0;
    padding-bottom: 0;
    list-style: none;
}

[is=teaser-list] > * {
    margin-bottom: var(--space-m);
}

[is=teaser-list][has~=half-width] {
    width: 50%;
}

ol[is=list],
ol[is=list] li{
    list-style: disc;
}


[is=definition-list] {
    display: grid;
    grid-template-columns: max-content auto;
    grid-gap: 0 var(--space-s);
    padding: 0;
}

[is=definition-list] > dt,
[is=definition-list] > dd {
    padding-bottom: var(--space-xs);
}

[is=step-list],
[is=striped-list] {
    list-style: none;
    padding: 0;
    margin: var(--space-s);
    margin-bottom: var(--space-l);
    counter-reset: count 0;
}

[is=step-list] li,
[is=striped-list] li {
    list-style: none;
    padding: var(--space-s);

}

[is=step-list] li {
    counter-increment: count 1;
    padding-left: var(--space-m);
    margin-left: var(--space-m);
}

[is=striped-list] li:nth-child(odd) {
    background-color: var(--background-darker-color);
}


[is=step-list] li::before {
    --size: var(--size-l);
    content: counter(count, decimal) ".";
    display: inline-grid;
    text-align: center;
    justify-items: center;
    align-items: center;
    border-radius: 100%;
    margin-right: var(--space-xs);
    margin-left: calc((var(--space-l) + var(--space-xs))* -1);
    width: var(--size);
    height: var(--size);
    background: var(--foreground-color);
    color: var(--background-darker-color);
    font-size: var(--font-size-sm);
}