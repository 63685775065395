[is=overlay-container] {
    z-index: 1000;
    position: fixed;
    top:0 ;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

[is=overlay-container][has~=modal-style]{
    background: rgba(0, 0, 0, 0.5);
}

[is=overlay-container][has~=bottom-position]{
    align-items: flex-end;
}

[is=overlay-container][has~=left-position]{
    justify-content: left;
}

[is=overlay-container][has~=left-position] [is$=-popover]{
    transform: translate(-40vw, 0);
    transition: transform ease-in-out 0.3s;
}


[is=overlay-container][has~=left-position][now~=opened] [is$=-popover]{
    transform: translate(0, 0);
}

[is=popover],
[is$=-popover]{
    min-width: 40vw;
    background: var(--grey-darker-color);
    border-radius: var(--size-s);
    box-shadow: var(--default-box-shadow);
    overflow: hidden;
    scrollbar-width: thin;
    scrollbar-color: black var(--grey-darker-color);
}

[has~=mobile-layout] [is=popover],
[has~=mobile-layout] [is$=-popover]{
    min-width: 90vw;
    background: var(--grey-darker-color);
    border-radius: var(--size-s);
    box-shadow: var(--default-box-shadow);
    overflow-y: auto;
    overflow-x: hidden;
}

[is$=-popover][has~=small-size] {
    min-width: 400px;
    --popup-width: 30vw;
    width: var(--popup-width);
    margin-bottom: var(--bottom-gap);
    max-width: var(--max-content-width);
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
}

[has~=mobile-layout] [is$=-popover][has~=small-size] {
    min-width: 300px;
}

[is$=-popover] [is=closer]{
    right: var(--space-xs);
    top: var(--space-xs);
    position: absolute;
    z-index: 2;
    cursor: pointer;
}

[is=back-link],
[is$=-popover] [is=back-link] {
    justify-self: start;
    --font-size: calc(var(--size-m) + var(--size-xs));
    padding-left: 0;
    cursor: pointer;
}


[is$=-popover] [is=closer]::before{
    --font-size: var(--font-size-l);
}

[is=overlay-container][has~=bottom-position] > [is=popover],
[is=overlay-container][has~=bottom-position] > [is$=-popover]{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

[is=popover-content]{
    padding: var(--space-m);
    padding-top: 0;
}

[is=popover-inner-content]{
    padding: var(--space-m);
}

[is=popover-inner-content] p[has~=small-font]{
    font-size: var(--font-size-s);
}

[is=popover-inner-content] [is=form-row]{
    margin-top: var(--space-m);
}

[is=popover-inner-content] [is=button-row]{
    margin-top: var(--space-l);
}

[is=popover-inner-content] a{
    cursor: pointer;
    text-decoration: underline;
}

[is=popover-inner-header]{
    display: grid;
    grid-template-columns: 1fr 30px;
    background: var(--grey-darker-color);
    padding: var(--space-s);
}

[is=popover-inner-header] h1 {
    text-align: center;
}

[is=popover-header]{
    height: var(--size-xl);
    display: grid;
    grid-template-columns: 1fr 32px;
    background: var(--grey-darker-color);
    padding: var(--space-s);
    border-top-right-radius: var(--size-s);
    border-top-left-radius: var(--size-s);
}

[is=popover-header]  h2 + [is=closer] {
    position: static;
}

[is=popover-header] button {
    background: none;
}

[is=popover-header] h2 {
    padding-left: var(--space-xs);
}

[is=dialog-popover] {
    background: var(--grey-darker-color);
    padding: var(--space-s);
}

[is=popover-title] {
    display: grid;
    grid-template-columns: 1fr calc(var(--space-l) + var(--space-s));
    text-align: left;
   /* margin-bottom: var(--space-m);*/
    padding: var(--space-xs) var(--space-s);
    background-color: var(--grey-darker-color);
    font-size: var(--font-size-m);
    align-items: center;
    height: var(--header-height);
    margin-top: var(--top-gap);
}

[has~=mobile-layout]  [is=popover-title] {
    height: var(--mobile-header-height);
}

[is=popover-content] [is=button-row] {
    margin-top: var(--space-l);
}


[is$=-popover][has~=navigation-layout]{
    border-radius: 0;
   /* padding-top: var(--bottom-gap); */
}


[has~=mobile-layout] [is=overlay-container][has~=navigation-layout] {
    align-items: start;
    height: 100vh;
}

[has~=mobile-layout]  [has~=navigation-layout] [is=popover],
[has~=mobile-layout]  [has~=navigation-layout] [is$=-popover] {
    min-width: 100vw;
}


[has~=navigation-layout] [is=popover-content] {
    height: calc(100vh - var(--header-height) - var(--top-gap));
    overflow: auto;
}

[has~=mobile-layout] [has~=navigation-layout] [is=popover-content] {
    height: calc(100vh - var(--mobile-header-height) - var(--top-gap));
}


[is$=-popover][has~=navigation-layout] [is=back-link]{
    padding-left: 0;
}


[is$=-popover][has~=navigation-layout] [is=back-link]::before {
    --font-size: calc(var(--size-m) + var(--size-xs));
}

