[is=fleebs-pager] {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
}

[is=fleebs-pager] li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;

}

[is=fleebs-pager] li a {
    cursor: pointer;
    background-color: var(--background-darker-color);
    /* padding: var(--space-s); */
    border-radius: 50%;
    display: inline-block;
    width: var(--size-l);
    height: var(--size-l);
    text-align: center;
    line-height: var(--size-l);
    margin-right: var(--space-xs);
}

[is=fleebs-pager] li[now~=selected] a {
    background-color: var(--call-to-action-dark-color);
}