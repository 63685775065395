[is=dropdown]{
    --height: 0;
    --max-height: 500px;

    padding: 0;
    margin: 0;
    max-height: var(--height);
    position: absolute;
    overflow: hidden;
    transition: height 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    text-align: left;
    background: var(--background-darker-color);
    border-radius: var(--size-s);
    box-shadow: var(--default-box-shadow);
    overflow: hidden;
    z-index: 20;
}


[is=dropdown] li {
    padding: var(--space-s);
    white-space: nowrap;
}

[is=dropdown] a{
    color: var(--forground-color);
    text-decoration: none;
    cursor: pointer;
}

[is=dropdown][now~=open]{
    --height: var(--max-height);
}


[is=dropdown] {
    top: var(--size-xl);
    transition: max-height 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), top 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[is=dropdown][has~=right-position]{
    left: auto;
    right: var(--space-s);
}


[has~=mobile-layout] [is=dropdown][now~=open] {
    /* top: calc(var(--height) * -1); */
}

[has~=mobile-layout] [is=dropdown] {

}
