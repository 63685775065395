[is=fleeb-teaser] {

}


[is=fleeb-details] [is=picture-container] {
    display: block;
    height: calc(40vh + var(--top-gap) + var(--bottom-gap));
    overflow: hidden;
}


[is=fleeb-details] div > picture-loader {
    display: block;
    height: calc(40vh + var(--top-gap) + var(--bottom-gap));
    position: relative;

}

[is=fleeb-details][has~=text-layout] div > picture-loader{
    height: 0;
}

[has~=mobile-layout]  [is=fleeb-details] > div > div > picture-loader bar-loader{
   bottom: auto;
   top: 0;
}
