[is=tab-navigation] {
    display: flex;


    & [is=tab-navigation-item] {
        padding: var(--space-s);
        background: var(--background-lighter-color);
        opacity: 0.5;
        cursor: pointer;

        &:first-child {
            border-top-left-radius: var(--border-radius-m);
        }

        &:last-child {
            border-top-right-radius: var(--border-radius-m);
        }

        &[has~=selection] {
            opacity: 1;
        }
    }

    
}

tab-entry[hidden] {
    display: none;
}

[is=tab-content] {
    background-color:var(--background-lighter-color);

    border-bottom-left-radius: var(--border-radius-m);
    border-bottom-right-radius: var(--border-radius-m);
    border-top-right-radius: var(--border-radius-m);
    
    & fieldset {
        background-color:var(--background-lighter-color);

        & legend {
            margin-left: 0;
        }
    }
} 

