[is=logo]{
    line-height: 40px;

}

[is=logo] img {
    height: 40px;
    padding-left: var(--space-m);
    vertical-align: middle;
}




@media (max-width: 620px) {
    [is=logo] img,
    [has~=mobile-layout] [is=logo] img {
        padding: 0;
        height: 30px;
        margin-top: 2px;
    }

    header,
    body header,
    body header[is=bar],
    [has~=mobile-layout] header {
        --padding: 0;
        padding: 0;
    }
}


header [is=dropdown]{
    left: auto;
    right: var(--space-s);
}

@media (max-width: 620px) {

    header [is=dropdown],
    [has~=mobile-layout] header [is=dropdown]{
        right: auto;
        left: var(--space-xs);
        margin-top: calc(var(--top-gap) * -1);
    }

    header [is~=user-group] [is=dropdown],
    [has~=mobile-layout] header [is~=user-group] [is=dropdown] {
        left: auto;
        right: var(--space-xs);
    }
    
    body > app-root > :not(home-page) [is=filter],
    [has~=mobile-layout] > app-root > :not(home-page) [is=filter] {
        display: block;
        text-align: right;
        z-index: 5;
    }

    body > app-root > :not(home-page) [is=options],
    [has~=mobile-layout] > app-root > :not(home-page) [is=options] {
        display: grid;
        text-align: left;
        justify-content: end;
        align-items: start;
        align-content: center;
    }

    [is=filter] > input[type=text],
    [has~=mobile-layout] [is=filter] > input[type=text]{
        display: none;
    }

    body > app-root > :not(home-page) [is=filter] > button,
    [has~=mobile-layout] > app-root > :not(home-page) [is=filter] > button {
        background: none;
        padding: var(--space-s);
        display: inline-block;
        width: auto;
    }



    body > app-root > :not(home-page) [is=filter] > button:hover,
    body > app-root  > :not(home-page) [now~=filtering] [is=filter]:focus,
    [has~=mobile-layout] > app-root > :not(home-page) [is=filter] > button:hover,
    [has~=mobile-layout] > app-root  > :not(home-page) [now~=filtering] [is=filter]:focus {
        background: none;
    }

}


@keyframes paused-animation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
}

[is=options],
[is=user-group] {
    display: block;
    text-align: right;
}

[is=user-group] {
    display: flex;
}

[is=options] [is=navigation-ghost-button],
[is=user-group] [is=user-ghost-button] {
    display: inline-block;
}

@media (max-width: 620px) {
    [is=user-group] user-icon, 
    [is=user-group] [is=user-ghost-button],
    [has~=mobile-layout] [is=user-group] user-icon, 
    [has~=mobile-layout] [is=user-group] [is=user-ghost-button] {
        display: none;
    }

    [is=options] [is=navigation-ghost-button],
    [has~=mobile-layout] [is=options] [is=navigation-ghost-button] {
        display: none;
        align-self: center;
        align-content: center;
        padding: 0;
        margin: 0;
        padding-right: var(--space-s);
    }

    [is=back-link],
    [has~=mobile-layout] [is=back-link] {
        display: grid;
        align-self: center;
        align-content: center;
        padding-left: var(--space-s);
        
    }
}



/** centered header layout **/
[has~=centered-header-layout] [is=bar]{
    grid-template-columns: 1fr;
    align-items: center;
    align-content: space-around;
    justify-items: center;
}

[has~=centered-header-layout] [is=filter]{
    width: 60vw;
    max-width: var(--max-content-width);
}

[has~=centered-header-layout] [is=logo] {
    line-height: 100px;
    text-align: center;
}

[has~=centered-header-layout] [is=logo] img{
    height: 50px;
    padding: 0;
}

[has~=centered-header-layout] [is=options] {
    display: none;
}

[has~=centered-header-layout] fleebs-header [is=bar] {
    position: relative;
}

autocomplete [is=tag-cloud] {
    text-align: left;
    padding: var(--space-m);
}

@media (max-width: 620px) {
    [has~=centered-header-layout] [is=bar],
    [has~=mobile-layout] [has~=centered-header-layout] [is=bar]{
        grid-template-columns: 1fr;
        align-items: center;
        align-content: space-around;
        justify-items: center;
        position: static;
    }

    [has~=centered-header-layout] [is=options],
    [has~=mobile-layout] [has~=centered-header-layout] [is=options]{
        display: none;
    }

    [has~=centered-header-layout] [is=logo],
    [has~=mobile-layout] [has~=centered-header-layout] [is=logo]{
        grid-column: 1;
        grid-row: 1;
    }

    [has~=centered-header-layout] [is=filter],
    [has~=mobile-layout] [has~=centered-header-layout] [is=filter]{
        grid-column: 1;
        grid-row: 2;
        width: 85vw;
        max-width: var(--max-content-width);
    }

    [has~=centered-header-layout] [is=filter] button,
    [has~=centered-header-layout] [is=filter] input[type=text],
    [has~=mobile-layout] [has~=centered-header-layout] [is=filter] button,
    [has~=mobile-layout] [has~=centered-header-layout] [is=filter] input[type=text]{
        display: inline-block;
    }






    /* FilterMode  */
    body > app-root > :not(home-page) [now~=filtering] [is=filter] > input[type=text],
    [has~=mobile-layout]  > app-root > :not(home-page) [now~=filtering] [is=filter] > input[type=text]{
        display: block;
        top: 50px;
        position: absolute;
        z-index: 3;
        width: 100%;
        left: 0%;
        border-radius: 10px;
    }

    body > app-root > :not(home-page) [now~=filtering] [is=filter] > button,
    [has~=mobile-layout] > app-root > :not(home-page) [now~=filtering] [is=filter] > button {
        position: absolute;
        right: 0;
        top: 50px;
        z-index: 3;
    }

    body  > app-root > :not(home-page) [now~=filtering] detail-filter,
    [has~=mobile-layout] > app-root > :not(home-page) [now~=filtering] detail-filter {
        margin-top: 30px;
        padding-top: var(--space-l);
    }

    body > app-root > :not(home-page) [now~=filtering] [is=filter]>input[type=text]
    [has~=mobile-layout] > app-root > :not(home-page) [now~=filtering] [is=filter]>input[type=text] {
        padding-right: var(--space-xl);
        padding: var(--space-s);
    }
}
