home-page [is=bar]{
    grid-template-columns: 1fr;
    align-items: center;
    align-content: space-around;
    justify-items: center;
    margin-top: calc(20vh + (var(--top-gap)));
    top: auto;
}

[has~=mobile-layout] home-page [is=bar][has~=user-group],
home-page [is=bar][has~=user-group] {
    grid-template-columns: auto;
}


home-page [is=user-group] {
    position: absolute;
    top: var(--space-xs);
    right: var(--space-xs);
}

/*
[has~=mobile-layout] home-page [is=bar][has~=user-group] [is=filter] {
    grid-row: auto;
    grid-column: auto;
}*/

@media (max-width: 620px) { 
    [has~=mobile-layout] home-page [is=bar][has~=user-group] [is=filter],
    home-page [is=bar][has~=user-group] [is=filter] {
        grid-row: auto;
        grid-column: auto;
    }
}


home-page [is=filter]{
    width: 60vw;
    max-width: var(--max-content-width);
}

home-page ai-filter-input,
home-page filter-input {
    width: calc(100vw - (2 * var(--space-m)));
    z-index: 4;
}


home-page detail-filter {
    position: absolute;
    z-index: 3;
}

/*
[has~=mobile-layout] [is=filter]{
    width: 80vw;
}*/

home-page [is=logo] {
    line-height: 100px;
    text-align: center;
}

[has~=mobile-layout] home-page [is=logo] img,
home-page [is=logo] img{
    height: 45px;
    padding: 0;
}



home-page [is=bar] {
    position: static;
}



@media (max-width: 620px) {
    home-page [is=bar],
    home-page [is=bar][has~=user-button],
    [has~=mobile-layout] home-page [is=bar],
    [has~=mobile-layout] home-page [is=bar][has~=user-button]{
        grid-template-columns: 1fr;
        align-items: center;
        align-content: space-around;
        justify-items: center;
        margin-top: calc(20vh + (var(--top-gap)));
        /* this is becaouse ios can not interpret position:fixed if the keyboard is open*/
        position: static;
        top: auto;
    }

}


[has~=mobile-layout] home-page [is=options],
home-page [is=options]{
    display: block;
    position: absolute;
    top: var(--space-xs);
    left: var(--space-xs);
}


@media (max-width: 620px) {

    home-page [is=info-box] {
        padding: var(--space-s);
    }

    home-page [is=logo],
    [has~=mobile-layout] home-page [is=logo]{
        grid-column: 1;
        grid-row: 1;
    }

    home-page [is=filter],
    [has~=mobile-layout] home-page [is=filter]{
        grid-column: 1;
        grid-row: 2;
        width: 95vw;
        max-width: var(--max-content-width);
    }

    home-page [is=filter] button,
    home-page [is=filter] input[type=text],
    [has~=mobile-layout] home-page [is=filter] button,
    [has~=mobile-layout] home-page [is=filter] input[type=text]{
        display: inline-block;
    }

    home-page [is=filter],
    [has~=mobile-layout] home-page [is=filter] {
        grid-template-columns: 1fr;
        display: grid;
        align-items: stretch;
        justify-content: center;
        justify-items: center;
        overflow: hidden;
    }

    home-page [is=filter] ai-filter-input:not([now~=opened]),
    home-page [is=filter] ai-filter-input[now~=opened],
    home-page [is=filter] ai-filter-input,
    [has~=mobile-layout] home-page [is=filter] ai-filter-input:not([now~=opened]),
    [has~=mobile-layout] home-page [is=filter] ai-filter-input[now~=opened],
    [has~=mobile-layout] home-page [is=filter] ai-filter-input,
    home-page [is=filter] filter-input:not([now~=opened]),
    home-page [is=filter] filter-input[now~=opened],
    home-page [is=filter] filter-input,
    [has~=mobile-layout] home-page [is=filter] filter-input:not([now~=opened]),
    [has~=mobile-layout] home-page [is=filter] filter-input[now~=opened],
    [has~=mobile-layout] home-page [is=filter] filter-input{
        background: var(--background-darker-color);
        margin: 0;
        padding: 0;
        position: relative;
        top: auto;
        left: auto;
        width: calc(100vw - (2 * var(--space-m)));
    }

    home-page [is=filter] ai-filter-input [is=filter-button][has*=-icon]::before,
    [has~=mobile-layout] home-page [is=filter] ai-filter-input [is=filter-button][has*=-icon]::before,
    home-page [is=filter] filter-input [is=filter-button][has*=-icon]::before,
    [has~=mobile-layout] home-page [is=filter] filter-input [is=filter-button][has*=-icon]::before,
    [has~=mobile-layout] home-page [is=filter] ai-filter-input[now~=opened] speach-button button:before{
        --font-size: calc(var(--size-m))
    }

    home-page [is=filter-button],
    [has~=mobile-layout] home-page [is=filter-button],
    home-page [is=filter] ai-filter-input[now~=opened] [is=filter-button],
    [has~=mobile-layout] home-page  [is=filter] ai-filter-input[now~=opened] [is=filter-button],
    home-page  [is=filter] filter-input[now~=opened] [is=filter-button],
    [has~=mobile-layout] home-page  [is=filter] filter-input[now~=opened] [is=filter-button]{
        top: var(--space-xxs);
        height: calc(var(--size-l) + var(--space-xxs));
    }
/*
    [is=filter] ai-filter-input[now~=opened] [is=filter-button],
    [has~=mobile-layout] [is=filter] ai-filter-input[now~=opened] [is=filter-button],
    [is=filter] filter-input[now~=opened] [is=filter-button],
    [has~=mobile-layout] [is=filter] filter-input[now~=opened] [is=filter-button] {
        height: calc(var(--size-xxl));
    }*/

    [has~=mobile-layout] home-page  [is=filter] ai-filter-input[now~=opened] speach-button button {
        top: auto;
        position: static;
        height: auto;
    }
}


[is=coperate-navi] {
    padding: 0;
    margin: 0;
    list-style-type: none;
    width: 100%;
    text-align: center;
}

[is=coperate-navi] li {
    display: inline;
    padding: 0 var(--space-xs);
    margin: 0;
    font-size: var(--size-s);
    border-right: 1px solid var(--foreground-color);
}

[is=coperate-navi] li:last-child {
    border-right: none;
}

[is=coperate-navi] a {
    text-decoration: underline;
}

home-page fleeb-stream-teaser-list {
    margin-top: var(--space-m);
    width: 60vw;
    max-width: var(--max-content-width);
    display: block;
    margin-left: auto;
    margin-right: auto;
}


home-page [has~=centered-layout]  > *:not(router-outlet){
    max-width: 800px;
    width: 60vw;
}

home-page [is=bar] [is=filter-options] {
    margin-top: calc(20vh + (var(--top-gap)) + 100px);
    position: static;
    width: 60vw;
    margin-top: 0;
    grid-template-columns: 1fr;
    max-width: var(--max-content-width);
}

[has~=mobile-layout]  home-page  [is=filter-options] a {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

[has~=mobile-layout] home-page [is=bar] [is=filter-options] {
    margin-top: 0;
    position: static;
    width: 95vw;
    display: grid
}
 /*
home-page [has~=centered-layout]  [is=info-box] {
    margin-top: var(--space-l);
}*/




@media (max-width: 620px) {
    home-page fleeb-stream-teaser-list,
    [has~=mobile-layout] home-page fleeb-stream-teaser-list {
        width: 95vw;
    }

    home-page [is=user-group],
    [has~=mobile-layout] home-page [is=user-group] {
        top: var(--top-gap);
    }

    home-page [is=user-group] [is=user-ghost-button],
    [has~=mobile-layout] home-page [is=user-group] [is=user-ghost-button] {
        display: block;
    }

    home-page [has~=centered-layout]  > *:not(router-outlet),
    [has~=mobile-layout] home-page [has~=centered-layout]  > *:not(router-outlet){
        width: 100%;
    }

   

}






