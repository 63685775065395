[is=location-parts] {
    display: block;
    position: relative;
    height: 400px;

}

[is=location-parts] [is=location-row]{
    display: block;
}

[is=location-parts] detail-map{
    display: block;
    position: absolute;
    width: 100%;
    height: 400px;
    z-index: 0;
    top: 0;
}

[is=location-parts] detail-map mgl-map {
    height: 400px;
}

[is=location-options]{
    z-index: 1;
    position: absolute;
    top: var(--space-s);
    left: var(--space-s);
    background: var(--background-color);
    font-size: var(--size-s);
    padding: var(--space-s);
    width: 220px;
    opacity: 0.9;
}

[is=adress-name] {
    font-weight: 600;
    padding-bottom: var(--space-xs);
    display: inline-block;
}


[is=postalcode],
[is=region]{
    padding-right: var(--size-xs);
}

[is=geo-coordinates]{
    padding-top: var(--space-s);
}

[is=geo-coordinates] span{
    display: block;
}

[is=plus-code]::before {
    padding-right: var(--size-xs);
}

[is=longitude] span,
[is=latitude] span {
    font-size: var(--font-size-xs);
}

[is=longitude] {
    padding-bottom: var(--space-xxs);
}

[is=location-actions] {
    justify-self: end;
    align-self: end;
    z-index: 1;
    position: absolute;
    bottom: var(--space-s);
    left: var(--space-s);
}

[has~=mobile-layout]  [is=location-parts] {
    height: auto;
}

[has~=mobile-layout] [is=location-parts] detail-map {
    height: 300px;
    position: static;
}

[has~=mobile-layout] [is=location-parts] detail-map mgl-map {
    height: 300px;
}

[has~=mobile-layout] [is=location-options] {
    position: static;
    font-size: var(--font-size-sm);
    width: auto;
    background: none;
}

[has~=mobile-layout] [is=location-actions] {
   /* position: static; */
   left: auto;
   bottom: auto;
   top: var(--space-s);
   right: var(--space-s);
}

[is=marker] {
    width: 25px;
}

