
/*columns*/

[has~=three-columns]{

}

[has~="centered-aligment"]{
    text-align: center;
}

[has~=size-s]{
    --size: calc(var(--size-s));
}


[has~=size-m]{
    --size: calc(var(--size-m));
}


[has~=size-l]{
    --size: calc(var(--size-l));
}

[has~=size-xl]{
    --size: calc(var(--size-xl));
}

[has~=size-xxl]{
    --size: calc(var(--size-xxl));
}


[has~=small-text]{
    font-size: var(--font-size-s);
}

[has~=bold-text]{
    font-weight: bold;
}

[has~=underline-text]{
    text-decoration: underline;
}

[has~=cetered-aligment]{
    text-align: center;
}

[has~=right-aligment]{
    text-align: right;
}


[has~=fifty-fifty-columns]{
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: var(--space-s);
}


[has~=seventy-thirty-columns] {
    display: grid;
    grid-template-columns: 70% 30%;
    column-gap: var(--space-s);
}

[has~=eighty-twenty-columns] {
    display: grid;
    grid-template-columns: 80% 20%;
    column-gap: var(--space-s);
}

[has~=no-column-gap] {
    column-gap: 0;
}

[has~=small-top-padding]{
    padding-top: var(--space-s);
}


[has~=error-color]{
    color: var(--error-color);
}

[has~=error-border]{
    border: 1px solid var(--error-color);
}

[type="checkbox"][has~=error-border]{
    box-shadow: 0px 0px 0px 2px var(--error-color);
}

[has~=success-color]{
    color: var(--success-color);
}

[has~=l-paddings]
[has~=large-paddings] {
    padding: var(--space-l);
}

[has~=xl-paddings] {
    padding: var(--space-xl);
}

[has~=xxl-paddings] {
    padding: var(--space-xl);
}

[has~=s-bottom-margins] {
    margin-bottom: var(--space-s);
}

[has~=m-bottom-margins] {
    margin-bottom: var(--space-m);
}

[has~=l-bottom-margins] {
    margin-bottom: var(--space-l);
}

[has~=xl-bottom-margins] {
    margin-bottom: var(--space-xl);
}


[has~=no-bottom-margins] {
    margin-bottom: 0;
}

[has~=no-top-margins] {
    margin-top: 0;
}


[has~=l-icon-size] {
    --icon-size: var(--size-l);
}

[has~=xl-icon-size]{
    --icon-size: var(--size-xl);
}

[has~=xxl-icon-size]{
    --icon-size: var(--size-xxl);
}

[has~=l-icon-size]::before,
[has~=xl-icon-size]::before,
[has~=xxl-icon-size]::before{
    font-size: var(--icon-size);
}

[has~=pre-breaking]{
    white-space: pre-wrap;
}

[has~=rounded-corners] {
    border-radius: var(--size-s);
}

[has~=no-borders]{
    border: none;
}


[has~=right-m-margins] {
    margin-right: var(--space-m);
}


[has~=m-font-size] {
    font-size: var(--font-size-m);
}

[has~=warn-icon-color]::before,
[has~=warn-color]{
    color: var(--warn-color);
}



[has~=centered-layout] {
    width: 100vw;
   
    display: block;
    justify-content: center;
    justify-items: center;

    border-radius: var(--border-radius-s);
    max-height: 100%;
    

  /*  margin-top: var(--space-l);*/
  
  /*  padding: var(--space-m) calc(20vw + var(--space-s));*/
}

[has~=centered-layout] > *:not(router-outlet) {
    width: 100%;
    margin: 0 auto;
    overflow-x: hidden;
    max-width: 980px;
    display: block;
    padding: var(--space-m);
}


[has~=centered-layout] > *:not(router-outlet):has([is=form-container]) {
    max-width: 800px;
}




[has~=centered-content] {
    display: grid;
    height: 90vh;
    align-items: center;
    overflow: auto;
}


@media (max-width: 620px) {
    [has~=centered-layout],
    [has~=mobile-layout] [has~=centered-layout] {
        width: clamp(100px, calc(100vw), calc(var(--max-content-width) - (2* var(--space-s))));
        padding: var(--space-s) var(--space-m);
        /*width: clamp(100px, calc(95vw - (2 * var(--space-s))), calc(var(--max-content-width) - (2 * var(--space-s))));
        padding: var(--space-s);*/
    }
}






[has~=error-background] {
    background-color: var(--error-color);
}

[has~=success-background] {
    background-color: var(--success-color);
}

[has~=warn-background] {
    background-color: var(--warn-color);
}


[now~=fixed-position] {
    position: fixed;
}


[has~=middle-width] {
    width: calc(var(--size-xxl) * 2);
}

[has~=xl-top-margins] {
    margin-top: var(--space-xl);
}

[has~=xxl-top-margins] {
    margin-top: var(--space-xxl);
}

[has~=centered-items] {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    justify-items: center;
}
