[is=fleeb-teaser][has~=vehicle-type] [is=content] {
    grid-template-rows: 60% calc(40% - 30px) 30px;
}

[is=fleeb-teaser][has~=vehicle-type][has~=wide-teaser-layout] [is=content] {
    grid-template-rows: 1fr 30px;
}

[is=fleeb-teaser][has~=vehicle-type][has~=text-layout] [is=content][has~=offer] {
    grid-template-rows: calc(100% - 30px) 30px;
    padding-top: var(--space-l);
}

[has~=mobile-layout] [is=fleeb-teaser][has~=vehicle-type]  [is=content][has~=offer]{
    grid-template-rows: 50% calc(50% - 26px) 26px;
}


[has~=mobile-layout] [is=fleeb-teaser][has~=wide-teaser-layout][has~=vehicle-type]  [is=content][has~=offer]{
    grid-template-rows: 1fr 26px;
}


[has~=mobile-layout] [is=fleeb-teaser][has~=vehicle-type]  [is=offer] {
    top: calc(50% - var(--font-size-s));
}

[has~=mobile-layout] [is=fleeb-teaser][has~=wide-teaser-layout][has~=vehicle-type]  [is=offer] {
    top: var(--space-m);
}


/*

[has~=mobile-layout] [is=fleeb-teaser][has~=vehicle-type] [is=content][has~=offer] h2 {
    padding: 0.75rem;
    padding: var(--space-s);
    padding-bottom: 0.75rem;
    padding-bottom: var(--space-s);
    max-height: calc((var(--line-height) * var(--lines)) + 0.75rem);
    max-height: calc((var(--line-height) * var(--lines)) + var(--space-s));
}
*/



[has~=mobile-layout] [is=fleeb-teaser][has~=vehicle-type] [is=content] a {
    padding: var(--space-xxs);
}


