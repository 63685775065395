cookie-consent [is=overlay-container]{
    display: none;
}

cookie-consent [is=overlay-container][now=opened]{
    display: grid;
}

[is=cookie-consent-popover] {
    --width: 70%;
    display: none;
    width: var(--width);
    max-width: 800px;
    min-width: var(--width);
    z-index: 11;
    border-radius: var(--size-s);
    display: block;
    --max-popup-height: calc(100vh - (var(--space-l) + var(--space-s)));
    max-height: var(--max-popup-height);
    height: 100%;
}

[has~=mobile-layout] [is=cookie-consent-popover],
[has~=mobile-layout] [is=cookie-consent-popover].individual-settings {
    --width: 100%;
    max-width: 90vw;
    top: 0;
    align-self: end;
}

[has~=mobile-layout] [is=overlay-container]{
    overflow-y: auto;
    overflow-x: hidden;
}

[is=cookie-consent-popover].individual-settings {
    top: 10%;
}

[is=cookie-consent-popover] > [is=popover-header] h2 {
    padding: 0;
    padding-left: var(--space-xs);
}

[is=cookie-consent-popover] h3{
    margin-bottom: var(--size-xs);
    padding-left: var(--size-s);
}


cookie-details [has=separator-top]{
    border-top: 1px var(--background-lighter-color) solid;
    margin-top: var(--size-s);
    padding-top: var(--size-m);
}

cookie-details [has=margin-bottom]{
    margin-bottom: var(--size-l);
}

cookie-details [is=button], cookie-details [is$=-button]{
    margin-right: var(--space-s);
    margin-bottom: var(--space-s);
}

[is=cookie-consent-popover] [has~="scrollbar"]{
    overflow-y: scroll;
    height: calc(var(--max-popup-height) - var(--size-xl) - var(--size-s));
}
/*
cookie-consent [is=popover-content] {
    margin-bottom: var(--space-m);
    padding: var(--space-s) var(--space-m);
    max-height: calc(100% - (4* var(--space-s)));
    overflow: auto;
    height: calc(100% - (4* var(--space-s)));
}
*/


external-container [is=external-container-info]{
    color: var(--foreground-soft-color);
    background-color: var(--background-darker-color);
    padding: var(--space-s);
}

external-container [is=external-container-info] input[type=checkbox] {
    display: inline-grid;
    margin-left: var(--space-s);
}

external-container [is=external-container-info] span {
    vertical-align: super;
}