hot-today {
    text-align: center;
    display: block;
    margin-top: var(--space-l);
}

hot-today h3 {
    margin-bottom: var(--space-m);
}

hot-today [is=tag]{
    max-width: 100%;
    margin-bottom: calc(var(--space-s) * -1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


hot-today [is=collection-grid]{
    max-width: 632px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--space-l);
    padding-top: var(--space-m);
    align-content: center;
    align-items: center;
    justify-items: center;
    margin-right: auto;
    margin-left: auto;
}

[has~=mobile-layout] hot-today [is=collection-grid]{
    grid-template-columns: 1fr;
}

hot-today [is=collection-teaser]{
    height: 150px;
    width: 300px;
    border-radius: var(--space-s);
    display: flex;
    align-items: end;
    justify-content: end;
}

