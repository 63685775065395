

side-bar {
    --sidebar-width: calc(2 * var(--space-xxxl));

    display: block;
    position: fixed;
    z-index: 10;
    width: var(--sidebar-width);
    height: 100vh;
    padding: var(--space-s);
    transform: translate(var(--sidebar-width), 0);
    background-color: var(--background-darker-color);
    right: 0;
    top: 0;
    transition: transform 0.5s ease-in-out;
    text-align: left;
}

side-bar [is=close-ghost-button]{
    padding-left: 0;
}


side-bar[now~=open] {
    transform: translate(0, 0);
}

side-bar li {
    padding: var(--space-s);
}

body {
    transition: transform 0.5s ease-in-out;
}

[now~=sidebar-open] {

}