
[is$=-property],
[is=property] {
    padding-right: var(--space-m);
    display: inline-block;
    font-family: inherit;
}


[is=property][has*=-icon]::before{
    padding-right: var(--space-s);
}


