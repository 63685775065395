[is=info-box] {
    background-color: var(--content-box-color);
    margin-top: var(--space-l);
    padding: var(--space-m);
    border-radius: var(--border-radius-s);
}

@media (max-width: 620px) {

    [is=info-box],
    [has~=mobile-layout] [is=info-box] {
        max-width: 100%;
       /* max-width: clamp(100px, calc(95vw - (2 * var(--space-s))), calc(var(--max-content-width) - (2 * var(--space-s))));*/
        padding: var(--space-s);
    }

    [has~=centered-layout] >  [is=info-box] {
        padding: var(--space-s);
    }
}

[is=info-box] a[is=call-to-action-link] {
    margin-top: var(--space-m);
    text-align: right;
    width: 100%;
}