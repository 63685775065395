[has~=detail-layout] {
  display: block;
  border-radius: var(--default-border-radius);
  margin-bottom: var(--space-s);
}

[has~=detail-layout] [is=content] {
    border-radius: 10px;
    grid-template-rows: 1fr;
}

[has~=mobile-layout] [has~=detail-layout] [is=content] {
    grid-template-columns: 1fr;
    grid-template-rows: var(--size-xxl) 1fr calc(var(--size-xxl) + var(--size-s));
    border-radius: 10px;
}

[has~=detail-layout] picture-loader{
    margin: var(--space-s);
}

[has~=mobile-layout] [has~=detail-layout] picture-loader{
    margin: 0;
}

[has~=detail-layout] [is=content] h2 {
    padding: 0;
    margin-bottom: var(--space-m);
}

[has~=mobile-layout] [has~=detail-layout] [is=content] h2 {
    padding: 0;
    margin-bottom: var(--space-s);
    font-size: var(--font-size-sm);
}

[has~=detail-layout] [is=fleebs-content] {
    padding: var(--space-s);
}

[has~=detail-layout] podcast-fleebs-teaser{
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
}


[has~=detail-layout] podcast-fleebs-teaser {

}