password-field div{
    position: relative;
}

password-field input{
    padding-right: 44px;
}

password-field input:-webkit-autofill + [has*=-icon]:before{
    --color: var(--background-lighter-color);
}

password-field [has*=-icon]{
    position: absolute;
    right: 15px;
    top: 10px;
}