@import "./fonts.css";
@import "./external/maplibre.css";

@import "./theme.css";
@import "./default.css";
@import "./content.css";
@import "./icons.css";
@import "./bar.css";
@import "./filter.css";
@import "./structur.css";
@import "./button.css";
@import "./forms.css";
@import "./popover.css";
@import "./spinner.css";
@import "./links.css";
@import "./color.css";
@import "./grid.css";
@import "./list.css";
@import "./boxes.css";
@import "./tabs.css";

@import "./update-dialog.css";

@import "./navigation.css";

@import "./teaser/teaser.css";
@import "./teaser/layouts/wide-teaser.css";
@import "./teaser/layouts/list-teaser.css";
@import "./teaser/layouts/snippet-teaser.css";
@import "./teaser/layouts/detail-layout.css";




@import "./teaser/types/default.css";
@import "./teaser/types/product.css";
@import "./teaser/types/immo.css";
@import "./teaser/types/recipe.css";
@import "./teaser/types/domain.css";
@import "./teaser/types/book.css";
@import "./teaser/types/vehicle.css";


@import "./fleebs-detail-column.css";
@import "./suggest.css";
@import "./annotation.css";
@import "./drop-down.css";
@import "./details.css";
@import "./tag.css";
@import "./clamp.css";
@import "./property.css";
@import "./loader.css";
@import "./message.css";
@import "./hot-today.css";
@import "./header.css";
@import "./fleebs-history.css";
@import "./likes.css";
@import "./sidebar.css";
@import "./reporting.css";
@import "./cookie-consent.css";
@import "./detail-filter.css";
@import "./shorted-area.css";
@import "./suggest-bar.css";
@import "./vertical-scoller.css";
@import "./markdown.css";
@import "./password-field.css";
@import "./fleeb-column.css";
@import "./fleeb-row-teaser.css";
@import "./pager.css";
@import "./bar-loader.css";
@import "./picture-loader.css";
@import "./fleeb-collection.css";
@import "./toast-message.css";
@import "./footer-navigation.css";
@import "./filter-button.css";
@import "./dashboard.css";
@import "./user-icon.css";
@import "./hint-container.css";
@import "./user.css";

@import "./filter-creator/filter-creator.css";

/* pages must be set after elements */
@import "./pages/home-page.css";

@import "./detail-parts/event.css";
@import "./detail-parts/recipe.css";
@import "./detail-parts/job.css";
@import "./detail-parts/location.css";
@import "./detail-parts/product.css";
@import "./detail-parts/real-estate.css";
@import "./detail-parts/domain.css";



@import "./modifier.css";