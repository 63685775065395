
[is=fleeb-details] [is=more-like-this-grid] [is=fleeb-teaser]{
   
    --size: calc(
        (
            var(--container-width) - var(--gap) * (var(--container-columns) + 1)
        ) / var(--container-columns)
    );
    height: var(--size);
    width: var(--size)
}

[is=fleeb-details] [is=recipe-tile] [is=fleeb-detail-part] {
   /* width: calc(100% - 2 * var(--space-l));*/
}

[is=fleeb-details] [is=recipe-tile] h3{
    grid-column: 1/3;
}

[has=mobile-layout] [is=fleeb-details] [is=recipe-tile] {
    width:  100%;
    display: block;
 
}

[is=fleeb-details] [is=recipe-tile] [is=recipe-header]{
    padding: var(--space-m) 0 var(--space-m) 0;
    grid-column: span 2;
    padding: var(--space-m) 0 var(--space-l) 0;
}

[is=fleeb-details] [is=recipe-tile] [is=recipe-header] span{
    padding-right: var(--space-m);
    display: inline-block;
}

[is=fleeb-details] [is=recipe-tile] [is=recipe-header] span::before {
    padding-right: var(--space-s);
}

[is=fleeb-details] [is=recipe-tile] [is=recipe-footer] {
    grid-column: span 2;
    margin-top: var(--space-m);
    margin-bottom: 0;
}


[has~=mobile-layout] [is=fleeb-details] [is=recipe-tile] [is=fleeb-detail-part] {
    display: block;
    width: auto;
}


[is=fleeb-details] [is=recipe-ingredients] {
   width: 100%
}
