[is=grid] {
    display: grid;
}



[has~=four-quadratic-columns] {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap:var(--space-m);

}

[has~=six-quadratic-columns] {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap:var(--space-m);

}

[has~=six-quadratic-columns] > *,
[has~=four-quadratic-columns] > * {
    aspect-ratio: 1;
}


[has~=two-rectangle-columns] {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:var(--space-m);
}

[has~=four-rectangle-columns] {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap:var(--space-m);
}

[has~=four-rectangle-columns] > *,
[has~=two-rectangle-columns] > * ,
[has~=two-rectangle-columns] > [is=fleebs-teaser],
[has~=four-rectangle-columns] > [is=fleebs-teaser] {
    aspect-ratio: 2;
    width: auto; /* not set !important */
}

