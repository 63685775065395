[is=details-popover] {
    --popup-width: 95vw;
    height: calc(98vh - var(--top-gap) - var(--bottom-gap));
    width: var(--popup-width);
    margin-bottom: var(--bottom-gap);
    max-width: var(--max-content-width);
    background: var(--grey-darker-color);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    animation-name: popoverInitAnimation;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
}

[has~=mobile-layout] [is=details-popover] {
    --popup-width: 100vw;
    height: calc(100vh - var(--bottom-gap));
}


[has~=mobile-layout] [is=overlay-container][has~=bottom-position] > [is=details-popover] {
    border-radius: 0;
}


@keyframes popoverInitAnimation {
    0% {
        transform: translate(0, 100vh);
    }
    100% {
        transform: translate(0, 0);
    }
}


[is=details-popover] [is=closer]{
    margin-left: var(--space-xs);
    top: calc(var(--space-xs) + var(--top-gap) + 2vh);
    position: fixed;
    border-radius: 50%;
    background: rgba(33, 33, 33, 0.7);
    z-index: 2;
    aspect-ratio: 1;
    right: auto;
    z-index: 4;
 
}

[has~=mobile-layout] [is=details-popover] [is=closer]{
    top: calc(var(--space-xs) + var(--top-gap));
}

[is=details-popover] [is=closer]::before{
    --font-size: calc(var(--size-m) + var(--size-xs));
}


[is=details-popover] > [is=popover-content] {
    padding: 0;
    padding-bottom: var(--font-size-l);
    
}



/* default space */

[is=fleeb-details] > div > div > picture-loader picture {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
}

/* extra image size for similiar fleebs*/
[is=fleeb-details] similar-fleebs [is=fleeb-teaser] picture {
    height: 100%;
}

[is=fleeb-details] {
    display: block;
}

[is=fleeb-details] h2,
[is=fleeb-details] [is=description],
[is=fleeb-details] [is=main-actions]{
    padding: var(--space-m);
    padding-bottom: 0;
}

[is=fleeb-details] [is=description]{
    line-height: calc(var(--space-m) + var(--space-xxs));
}

[is=fleeb-details] [is=description][has=markdown] ul {
    margin-bottom: var(--space-m);
}

[is=fleeb-details] [is=description][has=markdown] li{
    list-style-type: square;
    margin-left: var(--space-l);
}

[is=fleeb-details] [is=description][has=markdown] :where(h1, h2, h3, h4){
    font-size: var(--font-size-ms);
    padding: 0 0 var(--space-xs) 0;
}

[is=fleeb-details] [is=description][has=markdown] h2{
    font-size: var(--font-size-m);
}

[is=fleeb-details] [is=description][has=markdown] h3{
    font-size: var(--font-size-sm);
}

[is=fleeb-details] [is=sharing-area]{
    display: block;
    width: 100%;
    text-align: right;
}

[is=fleeb-details] [is=sharing-area] li{
    display: inline-block;
    padding-right: var(--space-xs);
}

[is=fleeb-details] [is=detail-content] {
    overflow-x: hidden;
    overflow-y: auto;
}


[is=fleeb-details][has~=text-layout] [is=detail-content] {
    margin-top: calc(var(--space-xl) + var(--top-gap));
}

[has~=mobile-layout] [is=fleeb-details][has~=text-layout] [is=detail-content] {
    margin-top: calc(var(--space-xxl) + var(--top-gap));
}

[is=fleeb-details][has~=text-layout] [is=picture-container] {
    height: 0;
}

[is=fleeb-details] [is=main-actions]{
    --share-actions-width: calc((var(--space-xl) + var(--space-m)) * 4);
    display: grid;
    grid-template-columns: calc(100% - var(--share-actions-width)) var(--share-actions-width);
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    background-color: transparent;
    box-shadow: 0;
    max-width: var(--max-content-width);
    border-radius: var(--size-s);
}

[is=fleeb-details] [is=main-actions][now~=fixed-position] {
    position: fixed;
    top: calc(100vh - (98vh - var(--top-gap) - var(--bottom-gap)));
    padding-top: calc(var(--space-xl) + var(--space-xxs) + var(--top-gap));
    width: 100%;
    padding-bottom: var(--space-s);
    background-color: var(--grey-darker-color);
    box-shadow: var(--default-box-shadow);
    z-index: 3;
}




[has~=mobile-layout] [is=fleeb-details] [is=main-actions][now~=fixed-position] {
    top: 0;
    padding-top: calc(var(--space-xl) + var(--space-m) + var(--top-gap));
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}




[is=fleeb-details] [is=main-actions][now~=fixed-position] + * h2 {
    padding-top: calc(var(--size-xxl) - 3px);
}
/*
[has~=mobile-layout] [is=fleeb-details] [is=main-actions][now~=fixed-position] + * h2 {
    padding-top: calc(var(--size-xxl));
}*/




[is=fleeb-details] [is=main-actions] [is=website-button]{
    padding: var(--space-xs) var(--space-s);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: var(--size-s);
    display: block;
}


[is=fleeb-details] [is=main-actions]  [is=bookmark-rounded-ghost-button] {
    position: relative;
    height: 38px;
    width: 38px;
    --imageSize: 20px;

    & img{
        width: var(--imageSize);
        height: var(--imageSize);
        border-radius: 50%;
        vertical-align: bottom;
        background: var(--foreground-color);
        position: absolute;
        left: calc(50% - (var(--imageSize) / 2));
        right: auto;
        z-index: 1;
    }

    &::before {
        z-index: 2;
        position: relative;
        top: -15px;
        right: -15px;
        background: var(--background-color);
        border-radius: 50%;
    }
}

[has~=mobile-layout] [is=fleeb-details] [is=main-actions] [is=website-button]{
    --width: calc(100% - 2 * var(--space-m));
    z-index: 5;
    position: absolute;
    width: var(--width);
    max-width: var(--width);
    left: var(--space-m);
    margin-top: calc(var(--space-xl) * -1);
    transition: 0.2s ease-in-out; 
}

[has~=mobile-layout] [is=fleeb-details] [is=main-actions] [is=website-button][now~=fixed-and-small] {
    left: var(--space-xxl);
    position: fixed;
    top: calc(var(--space-xl) + var(--space-s) + var(--top-gap));
}


[is=fleeb-details] [is=main-actions] [is=website-button][now~=fixed-position]{
    position: relative;
}

[has~=mobile-layout] [is=fleeb-details] [is=main-actions] [is=website-button][now~=fixed-position] {
    left: var(--space-xxl);
    position: fixed;
    top: calc(var(--space-xl) + var(--space-s) + var(--top-gap));
}

[has~=mobile-layout] [is=fleeb-details] [is=main-actions] [is=website-button][now~=small-size] {
    --width: calc(100% - 2 * var(--space-xl));
    left: var(--space-xxl);
}


[is=fleeb-details] [is=tile-grid] {
    display: flex;
    flex-wrap: wrap;
}

[is=fleeb-details] [is=tile],
[is=fleeb-details] [is$=-tile]{
    border-top: 1px var(--background-lighter-color) solid;
    width: 50%;
}

[has~=mobile-layout] [is=fleeb-details] [is$=-tile] {
    border: none;
}

[is=fleeb-details] [is=tile] h3,
[is=fleeb-details] [is$=-tile] h3{
    padding: var(--space-m) 0;
}


[is=fleeb-details] [is=location-tile] [is=coordinats-group]{
    padding: var(--space-xs) 0;
}

[is=fleeb-details] [is=location-tile] [is$=-coordinats] {
    display: block;
}

[is=fleeb-details] [is=video-tile] {
    width: 100%;
}

[is=fleeb-details] [is=video-tile] [is=video-container],
[is=fleeb-details] [is=video-tile] [is$=-video-container]{
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
    margin-bottom: var(--space-m);
}

[is=fleeb-details] [is=video-tile] [is=video-container] iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

similar-fleebs {
    display: block;
     width: 100%;
}

[is=fleeb-details] [is=more-like-this-tile] {
    min-height: auto;
    width: 100%;
    margin-top: var(--space-m);
}

[is=fleeb-details] [is=more-like-this-grid] {
    --gap: var(--space-m);
    --grid-gap: var(--space-m);
    display: grid;
    grid-template-columns: repeat(var(--container-columns), 1fr);
    grid-gap: var(--grid-gap);
}


[is=fleeb-details] [is=property-list] [is=property-description] {
    font-size: var(--font-size-s);
}

[is=fleeb-details] [is=property-list] [is=property-value] {
    font-weight: bold;
}


[is=popover-content] [is=fleeb-details] [is=more-like-this-grid] h2{
    padding: var(--space-s);
    padding-bottom: 0;
}

[is=video-tile] [is=external-video-container]{
    text-align: center;
    position: relative;
}

[is=video-tile] [is=external-video-container] img {
    width: 100%;
}


[is=video-tile] [is=video-link]{
    --space: var(--space-s);
    --color: var(--foreground-color);
    position: absolute;
    color: var(--color);
    padding: var(--space);
    left: calc((100% - var(--icon-size) - var(--space)) / 2);
    top: calc((100% - var(--icon-size) - var(--space)) / 2);
    background: var(--grey-darker-color);
    border-radius: 50%;
    opacity: 0.8;
    border: 3px var(--color) solid;
    box-shadow: 0px 0px 6px var(--grey-dark-color);
}

[is=video-tile] [is=video-title] {
    position: absolute;
    padding: var(--space-s);
    text-shadow: 0 0 4px black;
    text-align: left;
}

[is=video-tile] [is=video-duration]{
    font-size: var(--font-size-s);
}


[is=fleeb-detail-part] {
    border-top: 1px var(--background-lighter-color) solid;
    padding-bottom: var(--space-m);
    margin-right: var(--space-m);
    margin-left: var(--space-m);
    margin-top: var(--space-m);
}

[is=fleeb-detail-part] h3 {
    padding-top: var(--space-m);
    padding-bottom: var(--space-m);
}

[is=paid-content-icon] {
    position: absolute;
    top: var(--space-xs);
    left: var(--space-s);
}


[is=fleeb-details] similiar-fleebs {
    display: block;
    max-width: 100%;
}

[is=fleeb-details] [is$=-rounded-ghost-button][hasUserLiked=true],
[is=fleeb-details] [is$=-rounded-ghost-button][hasUserLiked=true]::before{
    opacity: 0.6;
    color: var(--grey-light-color);
}

[is=fleeb-details] [is=property-collection] {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    padding-bottom: var(--space-m);
}

[is=fleeb-details] [is=property-collection][has~=grid-layout] {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: left;
}

[has~=mobile-layout] [is=fleeb-details] [is=property-collection][has~=grid-layout] {
    grid-template-columns: 1fr 1fr;
}


[is=fleeb-details] [is=property-collection] [is=property-list] {
    padding: var(--space-m) var(--space-m) 0 var(--space-m);
}

[is=fleeb-details] [is=property-collection] [is=property-list][has*=-icon] {
    display: grid;
    grid-template: "icon a"
                   "icon b"
}

[is=fleeb-details] [is=property-collection] [is=property-list][has*=-icon]:before {
    display: block;
    grid-area: icon;
    font-size: var(--font-size-l);
    margin-right: var(--space-s);
    align-self: center;
}


[is=feature-list] {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    padding-left: 0;
}

[is=feature-list] [is=tag] {
    margin-bottom: var(--space-s);
}

[is=fleeb-details] [is=affiliate-note]{
    top: unset;
    bottom: var(--space-s);
    left: var(--space-m);
}

[has~=mobile-layout] [is=fleeb-details] [is=affiliate-note]{
    bottom: calc(var(--space-l) + var(--space-xs));
}

[is=fleeb-details] similar-fleebs [is=affiliate-note],
[has~=mobile-layout] [is=fleeb-details] similar-fleebs [is=affiliate-note]{
    top: var(--space-xxs);
    bottom: unset;
    left: unset;
}


fullscreen-details-component > fleeb-details {
    text-align: center;
}

fullscreen-details-component > fleeb-details > div {
    max-width: var(--max-content-width);
    display: inline-block;
    text-align: left;
}

fullscreen-details-component [is=detail-content] {
    max-width: var(--max-content-width);
    display: inline-block;
    text-align: left;
}


[is=main-actions] [is=fixed-title] {
    position: absolute;
    top: calc(var(--space-m) - var(--space-xxs));
    left: var(--space-xl);
    max-width: calc(4 * var(--size-xxxxl));
    text-align: left;
    font-size: var(--font-size-m);
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}


[has~=mobile-layout] [is=main-actions] [is=fixed-title] {
    position: absolute;
    top: calc(var(--space-xxl) - var(--space-xs) + var(--top-gap));
    left: var(--space-m);
    max-width: calc(var(--size-xxxxl) - var(--space-s));
}


[is=detail-content] [now~=fixed] {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

