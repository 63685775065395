product-details [is=fleeb-detail-part]{
    text-align: right;
    position: relative;
    min-height: calc(var(--size-xxl) + var(--space-s));
    transition: transform 0.5s ease-in;
}

product-details [is=fleeb-detail-part] [is=price]{
    font-size: var(--font-size-xl);
    position: absolute;
    top: var(--space-s);
    right: var(--space-s);
}


[has~=mobile-layout] product-details [is=fleeb-detail-part] [is=price]{
    font-size: var(--font-size-l);
}


product-details [is=fleeb-detail-part] [is=price-type] {
    display: block;
    font-size: var(--font-size-sm);
    text-align: left;
}



