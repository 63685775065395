[is=user-bubble-add] {
    position: fixed;
    bottom: var(--space-m);
    margin-left: var(--space-m);
    width: calc(100% - (var(--space-m) * 2));
    max-width: calc(800px - var(--space-m) * 2);
    display: grid;
    grid-template-columns: 38px 38px 1fr;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    padding: var(--space-s);
    border-radius: var(--space-xxs);
    gap: var(--space-xs);

}