:root {
    /* Colors */
    --grey-light-color: #7E8082;
    --grey-dark-color: #2F2E2E;
    --grey-darker-color: #212121;

    --content-box-color: #1b1b1b;


    --background-darker-color: rgb(21,21,21);
    --background-color: rgb(36, 36, 37);
    --background-lighter-color: rgb(58, 58, 58);
    --foreground-color: #E5DADA;
    --foreground-soft-color: rgba(229, 218, 218, 0.5);

    --call-to-action-color: #BE1744;
    --call-to-action-dark-color: #860f2f;
    --call-to-action-light-color: #e7406d;
    --call-to-action-lighter-color: #ec7595;

    --error-color: rgb(191, 0, 0);
    --warn-color: #D76A03;
    --success-color: rgb(109, 166, 4);

    --color-scale-one: #8e2626;
    --color-scale-two: #8e265c;
    --color-scale-three: #81268e;
    --color-scale-four: #37268e;
    --color-scale-five: #26528e;
    --color-scale-six: #267c8e;
    --color-scale-seven: #268e8d;
    --color-scale-eight: #268e52;
    --color-scale-nine: #578e26;
    --color-scale-ten: #8d8e26;
    --color-scale-eleven: #8e6126;
    --color-scale-twelve: #8e2626;
    --color-scale-thirteen: #7a0737;

    --text-color: var(--foreground-color);
    --inverted-text-color: var(--background-color);
    --link-color: var(--primary-color);

    /* Borders */
    --border: 1px solid var(--dark-color);
    --border-radius: 0.2rem;
    --border-radius-s: var(--size-xs);
    --border-radius-m: var(--size-s);

    /* Indicator */
    --indicator-height: 3px;


    /* Page */
    --max-page-width: 1250px;


    /* Sizes */
   --size-unit: 1rem;
   --size-xxxxs: calc(0.0625 * var(--size-unit));
   --size-xxxs: calc(0.125 * var(--size-unit));
   --size-xxs: calc(0.25 * var(--size-unit));
   --size-xs: calc(0.5 * var(--size-unit));
   --size-s: calc(0.75 * var(--size-unit));
   --size-m: calc(1.25 * var(--size-unit));
   --size-ml: calc(1.5 * var(--size-unit));
   --size-mml: calc(1.75 * var(--size-unit));
   --size-l: calc(2 * var(--size-unit));
   --size-xl: calc(3.25 * var(--size-unit));
   --size-xxl: calc(5.25 * var(--size-unit));
   --size-xxxl: calc(8.5 * var(--size-unit));
   --size-xxxl: calc(8.5 * var(--size-unit));
   --size-xxxxl: calc(13.75 * var(--size-unit));

   --header-height: 68px;
   --mobile-header-height: 48px;
   --footer-height:  calc(var(--space-xl));

   --image-upload-size: 200px;

    /* Spaces */
    --space-unit: 1rem;
    --space-xxs: calc(0.25 * var(--space-unit));
    --space-xs: calc(0.5 * var(--space-unit));
    --space-s: calc(0.75 * var(--space-unit));
    --space-m: calc(1.25 * var(--space-unit));
    --space-l: calc(2 * var(--space-unit));
    --space-xl: calc(3.25 * var(--space-unit));
    --space-xxl: calc(5.25 * var(--space-unit));
    --space-xxxl: calc(8.5 * var(--size-unit));


    /* Text */
    --primary-font: 'Mulish';
    --secondary-font:  sans-serif;
    --font-unit: 1rem;
    --font-scale-ratio: 1.2;
    --font-weight: 200;


    --font-size-xxxs: calc(0.125 * var(--font-unit));
    --font-size-xxs: calc(0.25 * var(--font-unit));
    --font-size-xs: calc(0.5 * var(--font-unit));
    --font-size-s: calc(0.75 * var(--font-unit));
    --font-size-sm: calc(1 * var(--font-unit));
    --font-size-m: calc(1.25 * var(--font-unit));
    --font-size-ms: calc(1.375 * var(--font-unit));
    --font-size-ml: calc(1.5 * var(--font-unit));
    --font-size-mml: calc(1.75 * var(--font-unit));
    --font-size-l: calc(2 * var(--font-unit));
    --font-size-xl: calc(3.25 * var(--font-unit));
    --font-size-xxl: calc(5.25 * var(--font-unit));
    --font-size-xxxl: calc(8.5 * var(--font-unit));

    --letter-spacing: 0.5px;


    /* Forms */
    --input-background: var(--background-lighter-color);
    --input-color: var(--foreground-color);
    --input-border: 1px #555 solid;

    --default-box-shadow: 2px 2px 4px #000;
    --unshifted-thin-box-shadow: 0px 0px 2px #000;

    /* Content */
    --max-content-width: 800px;
}