[is=fleeb-teaser] recipe-fleebs-teaser[is=content] {
    grid-template-rows: 60% calc(40% - 30px) 30px;
}

[has~=mobile-layout] [is=fleeb-teaser] recipe-fleebs-teaser[is=content] {
    grid-template-rows: 60% calc(40% - 26px) 26px;
}
/*
[is=fleeb-teaser] recipe-fleebs-teaser[is=content] h2 {
    padding-top: var(--space-m);
    padding-bottom: var(--space-m);
    max-height: calc((var(--line-height) * var(--lines)) + var(--space-m));
}
*/

[has~=mobile-layout] recipe-fleebs-teaser [is="content"] {
    grid-template-rows: 60% calc(40% - 30px) 30px;
}


recipe-fleebs-teaser[has~=text-layout]  [is="content"] {
    grid-template-rows: calc(100% - 40px) 40px;
}


[is=fleeb-teaser] [is=recipe-info]{
    font-size: var(--font-size-sm);
    text-align: right;
    font-weight: bold;
    padding: var(--space-xxs) var(--space-s);
    position: absolute;
    top: calc(60% - var(--font-size-sm));
    right: var(--space-s);
    color: var( --foreground-color);
    border-radius: var(--size-s);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 85%;
}


[has~=mobile-layout] [is=fleeb-teaser] [is=recipe-info]{
    font-size: var(--font-size-xs);
    top: calc(60% - var(--font-size-m));
}

[is=fleeb-teaser][has~=text-layout] [is=recipe-info]{
    top: var(--space-s);
}
/*
[has~=mobile-layout]  [is=fleeb-teaser] recipe-fleebs-teaser[is=content] h2 {
    padding-bottom: var(--space-s);
}*/

[has~=mobile-layout] recipe-fleebs-teaser[has~=text-layout] [is="content"] {
    grid-template-rows: calc(100% - 30px) 30px;
}
