[has$=-annotation]::after{
    --size: var(--size-m);
    content : '';

    height: var(--size);
    width: var(--size);

    background-color: var(--call-to-action-color);
}

[is=annotation],
[is$=annotation] {
    --size: var(--size-m);
    content : '';

    height: var(--size);
    width: var(--size);

    background-color: var(--call-to-action-color);
    display: inline-block;
    position: absolute;
}