[is=popover-form-content] {
    text-align: center;
}

[is=form-container]{
    max-width: var(--max-content-width);
    width: 100%;
    position: relative;;
}

[is=form-container] h1{
    margin-bottom: var(--space-l);
}

[is=form-container] h2{
    margin-bottom: var(--space-m);
}

[is=form-row]{
    margin-bottom: var(--space-m);
}


[has~=label-toggle-columns] {
    display: grid;
    grid-template-columns: 1fr 60px;
}

[is=label-description] {
    font-size: var(--size-s);
    padding: var(--space-s);
    padding-top: 0;
    margin: 0;
}


[is=button-row],
[is$=-button-row]{
    text-align: right;
    margin-top: var(--space-xl);
}

[is=button-row] button {
    margin-right: var(--space-s);
    margin-bottom: var(--space-m);
}

[is=button-row] button:last-child{
    margin-right: 0;
}

[is=form-message]{
    width: 100%;
    padding: var(--space-s);
}


label {
    display: block;
    text-align: left;
    margin-bottom: var(--space-s);
}

[is=form-row][has~=checkbox]{
    display: grid;
    grid-template-columns:var(--size-m) auto;
    column-gap: var(--space-s);
}

[is=form-row][has~=checkbox] label{
    margin: 0;
}

[is=form-row][has="checkbox"] [is="form-message"]{
    grid-column: 1 / 3
}

[is=form-row][has~=checkbox] input[type="checkbox"]{
    /*
    margin-top: 2px;
    width: var(--size-unit);
    height: var(--size-unit);*/
}

[is=form-toggler-row] {
    display: grid;
    grid-gap: var(--size-s);
    background-color: var(--background-color);
    grid-template-columns: auto var(--size-m);
    font-size: var(--size-s);
    margin-bottom: var(--size-s);
    border-radius: var(--size-s);
    grid-template-columns: 1fr 60px;
}

input:where([type="text"], [type="number"], [type="email"], [type="url"], [type="password"]),
textarea,
select {
    width: 100%;
    padding: var(--space-s);
    border: none;
    border-radius: var(--border-radius-m);
    background: var(--background-lighter-color);
    color: var(--forground-color);
    font-size: var(--font-size-sm);
    line-height: var(--font-size-sm);
}

input:invalid, select:invalid {
    border: 1px var(--error-color) solid;
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px var(--background-lighter-color);
}


select {
    background-image:
      linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
}



[is=file-input-frame] {
    height: 200px;
    width: 100%;
    margin-top: -200px;
    z-index: 0;
    display: block;
    position: relative;
    border-radius: var(--size-xs);
    background: var(--background--color);
    color: var(--forground-color);
    font-size: var(--font-size-sm);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}


input[type="checkbox"] {
    appearance: none;
    background-color: var(--background-color);
    margin: 0;
    font: inherit;
    color: var( --grey-light-color);
    width: var(--size-ml);
    height:  var(--size-ml);
    border: 1px solid var(--foreground-soft-color);
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}
  
input[type="checkbox"]::before {
    content: "";
    width: var(--size-s);
    height: var(--size-s);
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    border-radius: 50%;
    box-shadow: inset var(--size-m) var(--size-m) var(--foreground-color);
    transform-origin: bottom left;
    /* clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);*/
    clip-path:  polygon(36% 73%, 79% 17%, 94% 18%, 63% 52%, 36% 86%, 4% 50%, 15% 50%);
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

input[type="checkbox"][has~=glow-shadow] {
    box-shadow: 0 0 3px #000;
}

input[type="checkbox"][has~=large-size] {
    width: var(--size-l);
    height: var(--size-l);
}

input[type="checkbox"][has~=large-size]:checked::before {
    transform: scale(1.2) translate(-1px, 1px);
}

/* toggle-button */
input[type="checkbox"][has~=toggle-style] {
    appearance: none;
    background-color: var(--background--color);
    margin: 0;
    font: inherit;
    /*  transition: 120ms border-color linear, 120ms  background-color linear;*/
    color: var(--grey-light-color);
    width: var(--size-xl);
    height: var(--size-ml);
    border: var(--size-xxxs) solid var(--grey-light-color);
    border-radius: var(--size-s);
    display: grid;
    place-content: center;
    cursor: pointer;
}

input[type="checkbox"][has~=toggle-style]:checked:disabled {
    background-color: var(--grey-light-color);
    border: var(--size-xxxs) solid var(--grey-light-color);
    cursor: default;
}

input[type="checkbox"][has~=toggle-style]:disabled {
    background-color: transparent;
    cursor: default;
}

input[type="checkbox"][has~=toggle-style]:disabled:before{
    opacity: 0.6;
}


input[type="checkbox"][has~=toggle-style]::before {
    content: "";
    display: block;
    width: var(--size-ml);
    height: var(--size-ml);
    transform: translate(calc(var(--size-l) / 2 * -1), 0);
    transition: 120ms transform ease-in-out;
    border-radius: 50%;
    box-shadow: inset var(--size-m) var(--size-m) var(--foreground-color),  0 2px 5px #000;
    transform-origin: bottom left;
    clip-path: none;
}

input[type="checkbox"][has~=toggle-style]:checked {
    --checked-color: var(--success-color);
    border-color: var(--checked-color);
    background-color: var(--checked-color);
}

input[type="checkbox"][has~=toggle-style]:checked::before{
    transform: translate(calc(var(--size-l) / 2), 0);
}

input[type=radio] {
    --size: var(--size-m);
    --border-size: 1px; /*  calc(var(--size)/8)*/
    --color: var(--foreground-color);
    
    height: var(--size);
    aspect-ratio: 1;
    width: var(--size);
    border: var(--border-size) solid #939393;
    padding: var(--border-size);
    background: 
        radial-gradient(farthest-side,var(--color) 98%,#0000) 
        50%/0 0 no-repeat content-box;
    border-radius: 50%;
    outline-offset: calc(var(--size)/10);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    font-size: inherit;
    transition: .3s;
    vertical-align: bottom;
      
}

input[type=radio]:checked {
    border-color: var(--color);
    background-size: 100% 100%;
}
  
input[type=radio]:disabled {
    background: 
        linear-gradient(#939393 0 0) 
        50%/100% 20% no-repeat content-box;
    opacity: .5;
    cursor: not-allowed;
}
  


label > input[type=radio] {
    margin-right: var(--space-xs);
}

fieldset {
    border: none;
    padding: var(--space-s) var(--space-xs);
    background: var(--background-color);
    border-radius: var(--border-radius-m);
}

fieldset:has(legend) {
    padding-top: var(--space-xl);
} 

fieldset legend{ 
    float: left;
    display: block;
    width: 100%;
    margin-top: calc((var(--space-l) + var(--space-xs)) * -1);
    margin-left: calc(var(--space-xs) * -1);

}

fieldset legend::after {
    content: ' ';
    display: block;
    clear: both;
    display: block;
}


fieldset:last-of-type {
    margin-bottom: 0;
}

fieldset [is=form-row]:last-child {
    margin-bottom: 0;
}

fieldset [is=form-row]:last-child label {
    margin-bottom: 0;
}

input[type=file] {
    width: var(--image-upload-size);
    position: relative;
    z-index: 1;
    display: block;
    height: var(--image-upload-size);
    position: absolute;
    z-index: 2;
    opacity: 0;
}

[is=image-upload] {
    
}

[is=image-preview] {
    height:var(--image-upload-size);
    width: var(--image-upload-size);
    object-fit: cover;
}



input[type=file][has~=round-shapes] + [is=image-preview] {
    border-radius: 50%;
}


[has~=in-field-button-layout] {
    position: absolute;
    padding-top: var(--space-s);
    right: 0;
    text-align: right;
    float: right;
    cursor: pointer;
}

