dashboard {
    --header-height: 100px;
    --footer-height: 0px;
    --dashboard-navigation-height: 58px;
    --paddings: var(--space-s);
    
    --content-height: calc(
        100vh 
        - var(--header-height) 
        - var(--footer-height)
        - var(--dashboard-navigation-height)
        - var(--paddings)
        - var(--top-gap)
        - var(--bottom-gap)
    );


    [is=scroll-button] {
        --size: calc(var(--size-xl) + var(--size-l));
        position: absolute;
        background: none;
        display: inline-grid;
        z-index: 10;
        background: var(--background-color);
        opacity: .8;
        border-radius: 50%;
        width: var(--size);
        height: var(--size);
        cursor: pointer;
        text-decoration: none;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin: 0;
        top: 50vh;

        &::before{
            font-size: calc(var(--font-size-xl));
        }

        &[has~=angle-left-icon] {
            left: var(--space-m);
        }

        &[has~=angle-right-icon] {
            right: var(--space-m);
        }
    }
}
[has~=centered-layout]>dashboard:not(router-outlet) {
    max-width: 1500px;
}

[has~=mobile-layout] dashboard {
    --header-height: 48px;
    --footer-height: 52px;
    --dashboard-navigation-height: 58px;
    --paddings: var(--space-m);
}

default-frame [is=content-area] dashboard {
    padding: var(--space-l) var(--space-l) 0px var(--space-l);
}

[has~=mobile-layout] default-frame [is=content-area] dashboard {
    padding: var(--space-m);
}

[is=stream-tile-fleebs-container] {
   /* --columns-width: calc(var(--size-xxl) * 3); */

    display: block;
   /* grid-template-rows: 1fr;
    grid-auto-flow: column dense;
    grid-gap: var(--space-m);
    */
    padding: var(--space-m) 0;
   /* overflow: scroll; */
    min-height: var(--size-xxl);

    & [is=fleeb-teaser] {
        margin-bottom: var(--space-m);

        &[has~=init-animation][now~=animation-ended]{
            animation:none;
        }

        &[has~=init-animation]:not([now~=animation-ended]) {
            animation-name: none;
            animation-duration: 1s;
            position: relative;
            animation-timing-function: ease-in-out;

            &:first-child {
                animation-name: none;
               
            }

          
            /*cubic-bezier(0.175, 0.885, 0.32, 1.275);*/

            [is=info],
            [is=content]{
                display: grid;
            }
            
        }

        &[has~=default-teaser]{
            width: 100%;
            height: calc(var(--size-xxl) * 3);

            &[has~=text-layout] {
                & [is=content]{
                    grid-template-rows: 1fr 35px;
                }
            }

            & [is=content] {
                grid-template-rows: 60% calc(40% - 30px) 30px;
            }

            & [is=teaser-icon]:before {
                padding-top: 4%;
            }
        }
    }
}




[is=stream-scoll-container] {
    overflow: hidden;
}


[has~=mobile-layout] [is=stream-scoll-container] {
    scrollbar-color: var(--background-color) var(--background-color);
    scrollbar-width: thin;
}

[is=stream-scoll-container] [is=start-stream-button] {

    --size: calc(var(--size-l) + var(--size-xs));
    position: absolute;
    background: none;
    display: inline-grid;
   

    z-index: 10;
    left: var(--space-s);
    background: var(--background-color);
    opacity: 0.9;
    border-radius: 50%;
    width: var(--size);
    height: var(--size);

    cursor: pointer;
    text-decoration: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 0;
}


[direction=vertical] [is=stream-scoll-container] [is=start-stream-button] {
    top: auto;
    left: auto;
    bottom: var(--space-m);
    right: 0;
  
}

[is=stream-scoll-container] [is=start-stream-button]::before {
    font-size: var(--size-m);
}


[has~=mobile-layout] [is=stream-tile-fleebs-container]  {
  /*  --columns-width: calc(var(--size-xxl) * 2); */
    -ms-overflow-style: none;
    scrollbar-width: none;
   /* padding: var(--space-s) 0;*/
}

/* 
[has~=mobile-layout] [mode=write] [is=stream-tile-fleebs-container] {
   padding: var(--space-m) 0; *
}*/

[has~=mobile-layout] [is=stream-tile-fleebs-container]::-webkit-scrollbar {
    display: none; 
}

dashboard-tile {
    margin-bottom: var(--space-s);
    display: block;
    position: relative;
    transition: filter 0.3s ease-out;

    scrollbar-color: var(--background-darker-color) var(--background-color);
    scrollbar-width: thin;

    &[active=false] {
       /* filter: grayscale(100);*/
    }

    & [is=info-box] {
        margin-top: 0;
    }

    & bar-loader {
        position: relative;
    }

    & h2[has~=disconnected-layout] {
        opacity: 0.8;
    }

    [is=tile-option-menu] {
        text-align: right;
        margin-bottom: calc(var(--space-l) * -1);
        position: absolute;
        width: 100%;
        z-index: 2;

        & button {
            display: inline-grid;
        }
       
    }

    [is=option-menu-button] {
        background: none;
        display: inline;

        &::before {
            font-size: var(--font-size-m);
        }
    }

    [is=tile-edit-options] {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    
}

[is=add-new-tile-area]{
    width: var(--columns-width);
    height: 70vh;
    background-color: var(--background-lighter-color);
    border-radius: var(--border-radius-s);
    display: grid;
    margin-top: var(--space-m);

    & button {
        background-color: var(--background-lighter-color);
    }
}



[mode=write] dashboard-tile h2 {
    text-overflow: ellipsis;
    max-width: 70vw;
}

[has~=mobile-layout] dashboard-tile {
  /*  width: calc(100% + var(--space-m));*/
}

[is=stream-tile-fleebs-container][has~=snippet-columns] [is=fleeb-teaser] {
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column dense;

    --size: var(--size-xxl);
    height: var(--size);
    width: 100%;
    aspect-ratio: auto;
}

[is=stream-tile-fleebs-container][has~=list-columns] [is=fleeb-teaser] {
    min-height: var(--size-xxl);
    width: 100%;
    aspect-ratio: auto;
}

[is=stream-tile-fleebs-container][has~=two-rectangle-columns] [is=fleeb-teaser] {
    min-height: calc(var(--size-xxl) * 3);
    height: calc(var(--size-xxl) * 3);
    width: 100%;
    aspect-ratio: auto;
}

[has~=mobile-layout]{
    & [is=stream-tile-fleebs-container] {
        & [is=fleeb-teaser][has~=default-teaser] {
            & [is=content] {
                grid-template-rows: 60% calc(40% - 25px) 25px;
            }

            &[has~=text-layout] {
                & [is=content]{
                    grid-template-rows: 1fr 25px;
                }
            }
            & h2 {
                --font-size: var(--font-size-sm);
                --line-height: var(--size-m);
            }

            & [is=teaser-properties] {
                margin-top: var(--space-xs);
            }

        }
        &[has~=two-rectangle-columns]  [is=fleeb-teaser] {
            min-height: calc(var(--size-xxl) * 2);
            height: calc(var(--size-xxl) * 2);
        }
    }
}


[is=edit-toggle-bar] {

    z-index: 10;
    min-width: var(--space-xl);
    display: grid;
    grid-template-columns: 150px 1fr 70px;
    justify-items: end;
    padding: var(--space-xs) var(--space-s);
    background-color: var(--grey-dark-color);
    margin-bottom: var(--space-l);
    border-radius: 10px;
}

[is=edit-toggle-bar] button {
    justify-self: start;
}
[is=edit-toggle-bar] input[type=checkbox][has~=toggle-style] {
    justify-self: end;
    align-self: center;
}

[is=edit-toggle-bar] label {
    display: inline;
    align-self: center;
    margin-bottom: 0;
    font-size: var(--font-size-s);
}

[is=tile-edit-options] {
    position: absolute;
    overflow: hidden;
    right: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: var(--space-xs);
    margin-right: calc(var(--space-l) + var(--space-xxs));
    z-index: 2;
    top: 0;
    /* background: var(--background-color); */
    padding: var(--space-s);
    border-radius: var(--border-radius-s);
    margin-top: calc(var(--space-s)* -1);
}


[is=dashboard-edit-row] {
    position: relative;
    top: calc(var(--space-l) * -1);
    left: 0;
    max-width: 200px;
}


tile-editor filter-input {
    width: 37vw;
    max-width: 37vw;
}


[is=dashboard-navigation]{

    display: block;
    margin-bottom: var(--space-m);
    white-space: nowrap;

    & li {
        display: inline-block;
        white-space: nowrap;
    }

    & a {
        font-size: var(--font-size-ml);
        padding: 0 var(--space-s) var(--space-xs) var(--space-s);
        display: inline-block;
        white-space: nowrap ;
        cursor: pointer;
        display: inline-block;
        max-width: calc(var(--size-xxl)* 4);
        overflow: hidden;
        text-overflow: ellipsis;

        &[now~=selected]{
           
        }
    }
}

[is=navigation-indicator]{
    display: inline-block;
    position: absolute;
    font-size: 0;
    height: var(--size-xxs);
    background-color: var(--call-to-action-color);
    transition: width 0.3s ease-out, left 0.3s ease-in-out;
    width: 100px;
    top: 38px;
    transform-origin: top left;
}


[is=tile-container] {
    --columns-width: calc(var(--size-xxl) * 6);
    --grid-gap: var(--space-m);

    grid-gap: var(--grid-gap);
    width: calc(var(--tile-length) * (var(--columns-width) + var(--grid-gap)));
    max-height: inherit;
   /* overflow: auto;*/


    &[direction=vertical] {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, var(--columns-width)));
        
        & stream-tile {
            overflow-x: hidden;
            overflow-y: auto;
            scrollbar-width: thin;
            scrollbar-color: black var(--grey-darker-color);
        }
    }

    & dashboard-tile {
        scroll-snap-align: start;
        max-height: inherit;
        --columns-width: calc((var(--columns-width) / 2) - var(--grid-gap));
       
    }

    & stream-tile {
        max-height: inherit;
    }
}

[is=vertical-scroll-area] {
    overflow-y: hidden;
    overflow-x: scroll;
    max-width: 100%;
    position: relative;
    scroll-snap-type: both mandatory;
    scrollbar-width: thin;
    scrollbar-color: black var(--grey-darker-color);

    &[has~=no-scrollbar] {
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &[has~=content-height] {
        max-height: var(--content-height);
    }
}

stream-tile[direction=vertical] {
   /* --columns-width: calc(var(--size-xxl) * 3); */
    max-width: var(--columns-width);
    display: block;
    
    & [is=stream-tile-fleebs-container] {
        /*
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-flow: row dense;
        grid-gap: var(--space-m);
        padding: var(--space-m) 0;
        min-height: var(--size-xxl);*/
    }
}

[has~=mobile-layout] {

    & [is=tile-container] {
        --columns-width: calc(100vw - (var(--space-m) * 2));
    }


    & tile-editor filter-input {
        width: 83vw;
        max-width: 83vw;
    }

    & [is=dashboard-edit-row] {
        position: relative;
        top: calc((var(--space-xl) + var(--space-s)) * -1);
        right: 0;
        text-align: right;
    }

}




@keyframes dashboardTeaserInit {
    0% {
        margin-top: calc((var(--calc-height) + var(--space-m)) * -1);
        top: calc(var(--space-m) * -1);
        opacity: 0;
    }

    100% {
        margin-top: 0;
        top: 0;
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        margin-top: 0;
        opacity: 1;
    }
}













