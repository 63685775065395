
[is=fleeb-teaser] [is=offer]{
    font-size: var(--font-size-sm);
    text-align: right;
    font-weight: bold;
    padding: var(--space-xxs) var(--space-s);
    position: absolute;
    top: calc(60% - var(--font-size-sm));
    right: var(--space-s);
    background: var( --foreground-color);
    color: var(--background-darker-color);
    border-radius: var(--size-s);
}

[is=fleeb-teaser][has~=wide-teaser-layout] [is=offer]{
    top: var(--space-m);
}

[has~=mobile-layout] [is=fleeb-teaser][has~=wide-teaser-layout] [is=offer]{
    top: var(--space-s);
}

[has~=mobile-layout] [is=fleeb-teaser] [is=offer]{
    font-size: var(--font-size-s);
    top: calc(60% - var(--font-size-s));
}

[is=fleeb-teaser] product-fleebs-teaser[is=content][has~=offer] {
    grid-template-rows: 60% calc(40% - 30px) 30px;
}
 
[is=fleeb-teaser][has~=wide-teaser-layout] product-fleebs-teaser[is=content][has~=offer] {
    grid-template-rows: 1fr 30px;
}
/*

[is=fleeb-teaser][has~=wide-teaser-layout] product-fleebs-teaser[is=content][has~=offer] h2 {
    padding-top: var(--space-m);
    padding-bottom: var(--space-m);
    max-height: calc((var(--line-height) * var(--lines)) + var(--space-m));
}
*/

[has~=mobile-layout] [is=fleeb-teaser] [is=content][has~="offer"] {
    grid-template-rows: 60% calc(40% - 26px) 26px;
}

[has~=mobile-layout] [is=fleeb-teaser] [is=content][has~="offer"] a {
    padding-top: var(--space-xxs);
}



[has~=mobile-layout] [is=fleeb-teaser][has~=wide-teaser-layout] [is="content"][has~="offer"] {
    grid-template-rows: 1fr 26px;
}

[is=fleeb-teaser][has~=text-layout] [is="content"][has~="offer"] {
    grid-template-rows: calc(100% - 30px) 30px;
}

[is=fleeb-teaser][has~=text-layout] [is=offer]{
    top: var(--space-s);
}

[has~=mobile-layout]  [is=fleeb-teaser] [is=content][has~=offer] h2 {
    margin-top: var(--space-xxs);
}

[has~=mobile-layout] [is=fleeb-teaser][has~=text-layout] [is="content"][has~="offer"] {
    grid-template-rows: calc(100% - 30px) 30px;
}


[has~=mobile-layout] [is=fleeb-teaser][has~=-layout] [is="content"][has~="offer"] {
    grid-template-rows: calc(100% - 30px) 30px;
}

/*
[has~=mobile-layout] [is=fleeb-teaser] [is=content][has~=offer] [is=text-row] {
    margin-top: var(--space-xxs);
}*/
