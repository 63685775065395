detail-filter{
    display: block;
    position: absolute;
    width: 100%;
    top: var(--space-xl);
    left: 0;
    background: var(--background-darker-color);
    border-bottom-left-radius: var(--size-s);
    border-bottom-right-radius: var(--size-s);
    margin-top: calc(var(--space-xs) * -1);
    padding: var(--space-m);
    z-index: 4;
}

detail-filter [is=detail-search] input[type=text]{
    background-color: var(--background-lighter-color);
    border-radius: var(--size-xs);
    width: 100%;
}

detail-filter [is=search-row] {
    display: grid;
    grid-template-columns: var(--size-xxxxl) 1fr var(--size-l);
    margin-bottom: var(--space-s);
}

detail-filter [is=button-row],
detail-filter [is$=-button-row]{
    padding-top: var(--space-s);
    padding-bottom: var(--space-s);
}

detail-filter [is=remove-icon-button]{
    padding: var(--space-xs);
    width: auto;
    border-radius: var(--size-s);
    margin-left: var(--space-xxs);
    display: inline-block;
}


detail-filter [is=contains-selection] {
    display: grid;
    grid-template-columns: var(--size-l) 1fr;
}

detail-filter [is=contains-selection] [is=condition] {
    justify-self: left;
    align-self: center;
}

detail-filter [is=contains-selection] [name=or] {
    height: 0;
    width: 0;
    visibility: hidden;
    float: right;
}

detail-filter [is=contains-selection] [is=toggle-button] {
    padding: 0;
    margin: 0;
    display: inline-block;
    width: auto;
    border-radius: var(--size-s);
    margin-right: var(--space-xxs);
    opacity: 0.3;
    background: none;
}

detail-filter [is=contains-selection] [name=or]:checked + [is=toggle-button] {
    opacity: 1;
}

detail-filter [is=contains-option] {
    margin-left: var(--space-xxs);
}

detail-filter [is=price-comparator]{
    display: grid;
    gap: var(--space-xxs);
    grid-template-columns: var(--size-xxl) auto;
}

detail-filter [is=location-option] {
    position: relative;
    display: grid;
    gap: var(--space-xxs);
    grid-template-columns: 1fr var(--size-xxl) var(--size-l);
}

detail-filter [is=radius-unit] {
    justify-self: center;
    align-self: center;
}

detail-filter [is=option-buttons] {
    justify-self: baseline;
    align-self: center;
}

detail-filter [is=filter-button-row] {
    grid-template-columns: 1fr 1fr;
    display: grid;
}

detail-filter [is=filter-button-row] [is=link-button]{
   padding: 0;
   justify-self: left;
}

detail-filter [is=filter-button-row] [is=link-button][has~=sms-icon]{
    justify-self: right;
}

detail-filter [is=save-filter-row] {
    margin-top: var(--space-m);
}

[is=suggest-entry] {
    display: block;
    padding-bottom: var(--space-xxs);
    cursor: pointer;

    &:hover,
    &[now~=selected] {
        background-color: var(--background-lighter-color);
    }

}

suggest-ai-bar {
    text-align: left;

    
}

[has~=mobile-layout] detail-filter{
  
    width: calc(100vw - (2 * var(--space-s)));
    border-radius: var(--size-s);
    padding: var(--space-s);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    
    left: var(--space-s);
    top: calc(var(--space-xxl) + var(--space-l) + var(--top-gap))
}

[has~=mobile-layout] detail-filter [is=search-row]{
    grid-template-columns: var(--size-xxxl) 1fr var(--size-m);
}


[has~=mobile-layout]  detail-filter [is=location-option] {
    grid-template-columns: 2fr 1fr var(--size-m);
}

[has~=mobile-layout] detail-filter [is=radius-unit]{
    font-size: var(--font-size-s);
}

[has~=mobile-layout] detail-filter [is=contains-selection] {
    grid-template-columns: var(--size-m) 1fr;
}

[has~=mobile-layout] [is=search-row] button{
    padding: 0;
    padding-left: var(--space-xxs);
    padding-right: var(--space-xxs);
}


[has~=mobil-layout] detail-filter [is=contains-selection] [is=toggle-button]{
    padding-right: var(--space-xxs);
}

[is=autocompletion-list]{
    background: var(--background-darker-color);
    position: absolute;
    top: var(--size-xl);
    text-align: left;
    padding: var(--space-xs);
}

[is=autocompletion-list] li{
    list-style: none;
    padding: var(--space-xs);
}

[is=autocompletion-list] li:hover{
    background-color: var(--background-lighter-color);
}