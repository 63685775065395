[is=icon-link]::before{
    padding-right: var(--space-s);
    vertical-align: top;
}

[is=call-to-action-link]{
    padding: var(--spaces-xs) 0;
    color: var(--call-to-action-lighter-color);
    text-decoration: underline;
    display: inline-block;
}


[is=inline-link] {
    text-decoration: underline;
}