
fleebs-loader {
    padding-top: var(--space-m);
    padding-bottom: var(--space-xl);
    z-index: 5;
    display: none;
    overflow: hidden;
}

fleebs-loader[now~=visible]{
    display: block;
}

[is=loader][has~=medium-size]{
    transform: scale(0.7) rotate(-15deg);
}

[has~=mobile-layout] [is=loader]{
    transform: scale(0.5) rotate(-15deg);
}

fleebs-loader[has~=centered-position] {
    margin-top: calc(45vh - 100px);
}

[is=loader] {
    position: relative;
    margin-top: var(--space-xl);
    transform: scale(0.8) rotate(-15deg);
}



[is=loader-text]{
    text-align: center;
    margin-top: var(--space-xxxl);
    font-size: var(--font-size-s);
}

[is=loader-bubble]{
    --size: var(--size-l);
    --duration: 2s;

    display: inline-block;
    background: white;
    height: var(--size);
    width: var(--size);
    border-radius: 50%;
    position: absolute;
    top: calc(50% - (var(--size) / 2));
    left: calc(50% - (var(--size) / 2));
    animation-name: loaderAnimation;
    animation-duration: var(--duration);
    animation-iteration-count: infinite;
    opacity: 0;
    transition: opacity var(--duration) ease-in-out, background-color var(--duration) ease-in-out;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[is=loader-bubble]:nth-child(2){
   /* animation-delay: calc(var(--duration) / 3);
    transition-delay: calc(var(--duration) / 3);*/
    animation-name: loaderAnimationTwo;
}

[is=loader-bubble]:nth-child(3){
   /* animation-delay: calc((var(--duration) / 3) * 2);
    transition-delay: calc((var(--duration) / 3) * 2);*/
    animation-name: loaderAnimationThree;
}

[now~=visible] [is=loader-bubble]{
    opacity: 0;
}

@keyframes loaderAnimation {
    0% {
        transform: translate(calc(var(--size) * -1), calc(var(--size) * -1)) scale(0.6);
        opacity: 0.7;
    }
    33% {
        transform: translate(calc(var(--size)), calc(var(--size) * -1));
       /* transform: translate(0, calc(var(--size) * -1)) scale(0.5);*/
        opacity: 1;
    }
    66% {
        transform: translate(0, calc(var(--size))) scale(0.3);
        opacity: 0.5;
    } 
    100% {
        transform: translate(calc(var(--size) * -1), calc(var(--size) * -1)) scale(0.6);
        opacity: 0.7;
    }
}


@keyframes loaderAnimationTwo {
    0% {
        transform: translate(calc(var(--size)), calc(var(--size) * -1));
       /* transform: translate(0, calc(var(--size) * -1)) scale(0.5);*/
        opacity: 1;
    }
    33% {
        transform: translate(0, calc(var(--size))) scale(0.3);
        opacity: 0.5;
    } 
    66% {
        transform: translate(calc(var(--size) * -1), calc(var(--size) * -1)) scale(0.6);
        opacity: 0.7;
    }
    100% {
        transform: translate(calc(var(--size)), calc(var(--size) * -1));
       /* transform: translate(0, calc(var(--size) * -1)) scale(0.5);*/
        opacity: 1;
    }
}

@keyframes loaderAnimationThree {
    0% {
        transform: translate(0, calc(var(--size))) scale(0.3);
        opacity: 0.5;
    } 
    33% {
        transform: translate(calc(var(--size) * -1), calc(var(--size) * -1)) scale(0.6);
        opacity: 0.7;
    }
    66% {
        transform: translate(calc(var(--size)), calc(var(--size) * -1));
       /* transform: translate(0, calc(var(--size) * -1)) scale(0.5);*/
        opacity: 1;
    }
    100% {
        transform: translate(0, calc(var(--size))) scale(0.3);
        opacity: 0.5;
    } 
}


[is=standalone-loader] {
    top: 0;
    position: absolute;
    z-index: 10;
    display: grid;
    height: 100vh;
    width: 100vw;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    pointer-events: none;
}