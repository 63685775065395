

[is=shorted-area] {
    --height: 400px;
    max-height: var(--height);
    overflow: hidden;
    position: relative;
}


[is=shorted-area]::after {
    content: '';
    display: block;
    z-index: 1;
    position: absolute;
    width: 100%;
    bottom: var(--space-l);
    height: var(--space-xxl);
    background: linear-gradient(180deg, transparent, var(--grey-darker-color), var(--grey-darker-color));
}

[is=shorted-area-footer]{
    position: absolute;
    bottom: 0;
    z-index: 2;
    height: var(--space-l);
    background-color: var(--grey-darker-color);
    width: 100%;
    border-top: 1px var(--background-lighter-color) solid;
}

[is=shorted-area-footer] [is=form-button]{
    top: calc((var(--font-size-sm) + var(--space-m)) / 2 * -1);
    position: relative;
}