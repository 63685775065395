

[is=footer-navigation] {
    --height: calc(var(--space-xl));
  
    position: relative;
    display: none;
   /* bottom: calc(var(--space-s) - var(--bottom-gap));*/
    background: var(--background-color);
    width: calc(100% - (2* var(--space-xs)));
    left: var(--space-xs);
    z-index: 4;
    height: var(--height);
   /* padding: var(--space-s);*/
  /*  border-radius: var(--border-radius-m);*/
    margin-top: calc((var(--footer-navigation-offset)) * -1);
}

[has~=mobile-layout] [is=footer-navigation] {
    display: block;
}

[is=footer-navigation-list] {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    list-style: none;;
    padding: 0;
    margin: 0;
    height: 100%;
}

[is=footer-navigation-list] li{
    padding: 0;
    margin: 0;
    align-self: center;
    justify-self: center;
    align-content: center;
    list-style: none;
}

[is=footer-navigation-list] [is=icon-link] {
    text-align: center;
    font-size: var(--size-s);
    display: grid;
}


[is=footer-navigation-list] [is=icon-link]::before{
    padding: 0;
    padding-bottom: var(--space-xxs);
}