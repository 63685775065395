fleebs-column {
    display: block;

    [is=teaser-option-menu] {
        text-align: right;
        margin-top: calc(var(--space-m) * -1);
        position: absolute;
        width: 100%;
        z-index: 2;

        & button {
            display: inline-grid;
        }
       
    }

    [is=teaser-actions] {
        position: absolute;
        overflow: hidden;
        right: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: var(--space-xs);
        margin-right: calc(var(--space-l) + var(--space-xxs));
        z-index: 2;
        top: 0;
        /* background: var(--background-color); */
        padding: var(--space-s);
        border-radius: var(--border-radius-s);
        margin-top: calc(var(--space-l) * -1);
       
    }
}

[has~=mobile-layout] fleebs-column {
    width: auto;
}

[is=fleeb-column-list] {
    margin: 0;
    padding: 0;
}

[is=fleeb-column-list] ul,
[is=fleeb-column-list] li{
    list-style: none;
    margin: 0;
    padding: 0;
}


[is=fleeb-column-list] > li {
    margin-bottom: var(--space-l);
    border-radius: var(--border-radius-s);
    position: relative;
}

[has~=mobile-layout] [is=fleeb-column-list] > li {
    margin-bottom: var(--space-l);

}

[is=fleeb-column-list] > li:nth-child(odd) {
    background-color: var(--background-darker-color);
}

[is=fleeb-column-list] > li > [is=selection-input] {
    position: absolute;
    right: calc(var(--space-xs) * -1);
    top: calc(var(--space-xs) * -1);
    z-index: 2;
}

[has~=mobile-layout] [is=fleeb-column-list] > li > [is=selection-input] {
    right: calc(var(--space-xs)* -1);
    top: calc(var(--space-xxs)* -1);
}


[is=column-action-bar] {
    padding: var(--space-m) 0;
}

/*
[is=fleeb-column-list] [is=teaser-actions] {
    position: absolute;
    right: calc(var(--space-xs)* -1);
    top: calc(var(--space-xs) * -1);
    z-index: 2;
}

[is=fleeb-column-list] [is=teaser-actions] [is=rounded-button] {
    margin-bottom: var(--space-xs);
}


[has~=mobile-layout] [is=fleeb-column-list] [is=teaser-actions] { 
    top: calc(var(--space-xxs) * -1);
}

[is=fleeb-column-list] [is=selection-input] + [is=teaser-actions] {
    top: calc(var(--size-l));
}*/