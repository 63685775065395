[is=reporting-popover] {
    --width: 95%;

    display: none;
    position: absolute;
    top: 30%;
    left: calc((100% - var(--width)) / 2);
    width: var(--width);
    min-width: var(--width);
    z-index: 11;
    border-radius: var(--size-s);
}


[is=reporting-popover] > [is=popover-header] h2 {
    padding: 0;
    padding-left: var(--space-xs);
}


[is=reporting-popover][now=opened] {
    display: block;
}


[is=reporting-overlay] {
    display: none;
    background: rgba(0,0,0,.5);
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
}

[is=reporting-overlay][now=opened] {
    display: block;
}



