[is=tag]{
    border-radius: var(--size-s);
    padding: var(--space-xs) var(--space-s);
    font-size: var(--font-size-sm);
    margin-left: var(--space-s);
    display: inline-block;
}

[is=tag][has~=small-paddings] {
    padding: var(--space-xxs) var(--space-s);
}

[is=tag][has*=-icon]::before{
    padding-right: var(--space-s);
}

[is=tag][has~=circle-layout] {
    margin: var(--space-s);
    max-width: calc(3 * var(--space-xl));
    padding: var(--space-xs) var(--space-s);
    aspect-ratio: 1/1;
    word-break: break-word;
    word-wrap: break-word;
    display: inline-flex;
    text-align: center;
    align-items: center;
}

[is=small-tag]{
    padding: var(--space-s);
    display: inline-block;
}

[is=tag-cloud] {
    text-align: center;
}

[is=tag-cloud] [is=tag]{
    margin: var(--space-s);
    max-width: calc(5 * var(--space-xxl));
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

[is=category-tag-box] [is=tag]{
    margin-bottom: var(--space-s);
}



