[is=fleeb-teaser][has~=snippet-layout] {
    --teaser-image-size:  var(--size-xl);
    grid-template-columns: 0 1fr;
    overflow: hidden;
}


[is=fleeb-teaser][has~=snippet-layout] [is=index-date],
[is=fleeb-teaser][has~=snippet-layout] [is=offer],
[is=fleeb-teaser][has~=snippet-layout] [is=icon],
[is=fleeb-teaser][has~=snippet-layout] [is=teaser-properties],
[is=fleeb-teaser][has~=snippet-layout][has~=text-layout] picture-loader {
    display: none;
}

[is=fleeb-teaser][has~=snippet-layout] [is=content],
[is=fleeb-teaser][has~=snippet-layout][has*=-type] [is=content],
[is=fleeb-teaser][has~=snippet-layout] product-fleebs-teaser[is=content][has~=offer],
[is=fleeb-teaser][has~=snippet-layout] immo-fleebs-teaser[is=content][has~=offer],
[has~=mobile-layout] [is=fleeb-teaser][has~=snippet-layout] recipe-fleebs-teaser[is=content],
[has~=mobile-layout] [is=fleeb-teaser][has~=snippet-layout] immo-fleebs-teaser[is=content][has~=offer],
[has~=mobile-layout] [is=fleeb-teaser][has~=snippet-layout] [is=content][has~=offer],
[has~=mobile-layout] [is=fleeb-teaser][has~=snippet-layout][has~=vehicle-type] [is=content][has~=offer],
[has~=mobile-layout] [is=fleeb-teaser][has~=snippet-layout][has~=text-layout] [is=content] {
    padding-left: calc(var(--teaser-image-size) + (2 * var(--space-s)));
    grid-template-rows: calc(100% - 30px) 30px;
}

[is=fleeb-teaser][has~=snippet-layout] [is=content] h2 {
    padding-top: var(--space-s);
}

[is=fleeb-teaser][has~=snippet-layout] picture-loader {
  
    position: absolute;
    border-radius: 50%;
    width: var(--teaser-image-size);
    height: var(--teaser-image-size);
    margin: var(--space-s);
    overflow: hidden;
}



[is=fleeb-teaser][has~=snippet-layout][has~=text-layout] [is=content]{
    padding-left: 0;
}


[is=fleeb-teaser][has~=snippet-layout][has~=text-layout] [is=content] h2 {
    align-self: start;
}
