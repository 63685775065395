toast-message {
    display: block;
    position: absolute;
    z-index: 1000;
    max-width: 300px;
    width: 35vw;
    right: var(--space-s);
    top: calc(var(--space-s) + var(--top-gap));
}

[has~=mobile-layout] toast-message {
    width: 85vw;
}


[is=toast-message] {
    border-radius: var(--border-radius-m);
    padding: var(--space-s);
    margin-bottom: var(--space-s);
    box-shadow: var(--default-box-shadow);
    background-color: var(--content-box-color);
    grid-template-columns: var(--space-l) 1fr var(--space-l);
    display: grid;
    align-items: start;
}


[is=toast-message][has~=error-style] [is=icon]::before{
    color: var(--error-color);
} 

[is=toast-message][has~=success-style] [is=icon]::before{
    color: var(--success-color);
} 

[is=toast-message][has~=warn-style] [is=icon]::before{
    color: var(--warn-color);
}

[is=toast-message] [is=toast-text],
[is=toast-message] [is=toast-description]{
    margin: 0;
    padding: 0;
}

[is=toast-message] [is=toast-text] + [is=toast-message] [is=toast-description] {
    margin-top: var(--space-xs);
}

[is=toast-message] [is=toast-description]{
    grid-column: 2;
    font-size: var(--font-size-s);
    text-overflow: ellipsis;
    overflow: hidden;
} 


[is=toast-message]  [is=icon][has~=times-icon] {
    justify-self: end;
    cursor: pointer;
}

[is=toast-message]  [is=icon][has~=times-icon]::before {
    color: var(--foreground-color);
}