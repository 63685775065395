


[is=fleeb-collection]{
    --gap: var(--space-m);
    --collection-width: clamp(100px, calc(60vw - (2 * var(--space-s))), calc(var(--max-content-width) -  (2 * var(--space-s))));

    display: grid;
    grid-template: 
        "collection-title collection-title"
        "teaser-one teaser-one"
        "teaser-two teaser-three"
    ;

    grid-gap: var(--gap);
    padding: var(--space-s);
    margin-bottom: var(--space-l);
    animation-name: fleebCollectionInitAnimation;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
}

@keyframes fleebCollectionInitAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100%;;
    }
}

[is=fleeb-collection]{

}

[has~=mobile-layout] [is=fleeb-collection] {
    --collection-width: clamp(100px, calc(95vw - (2 * var(--space-s))), calc(var(--max-content-width) -  (2 * var(--space-s))));
    --gap: var(--space-s);
}

[is=fleeb-collection] > h2 {
    grid-area: collection-title;
}

[is=fleeb-collection] > h2 [has*=-icon]::before{
    vertical-align: top;
    font-size: var(--font-size-l);
}


[is=fleeb-collection] [is=fleeb-teaser] {
    --size: calc((var(--collection-width) - 1 * var(--gap)) / 2);
}

[is=fleeb-collection] [is=fleeb-teaser]:nth-of-type(1) {
    --size: calc(var(--collection-width));
    height: calc(var(--size) / 2);
    grid-area: teaser-one;
}
/*
[has~=mobile-layout] [is=fleeb-collection] [is=fleeb-teaser]:nth-of-type(1) [is=content] {
    grid-template-rows: 60% calc(40% - 30px) 30px;
}*/

[is=fleeb-collection] [is=fleeb-teaser]:nth-of-type(1) picture{
    background-position: top;
}

[is=fleeb-collection] [is=fleeb-teaser]:nth-of-type(2) {
   grid-area: teaser-two;
}

[is=fleeb-collection] [is=fleeb-teaser]:nth-of-type(3) {
   grid-area: teaser-three;
}

[is=fleeb-collection] [is=fleeb-teaser]:nth-of-type(4) {
    grid-area: teaser-four;
    display: none;
}
