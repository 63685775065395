
nav {
    height: 100%;
}

[is=main-navigation]{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: right;
    justify-content: center;
    align-items: center;
    height: 100%;
}

[has~=mobile-layout] [is=main-navigation]{
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: start;
}

[has~=mobile-layout] [is=main-navigation] > li:first-child {
    display: none;
}

[is$=-navigation-button] {
    --background: none;

    padding: 0.6rem;
    background: none;
}


[is$=-sidenavi]{
    padding: var(--space-l);
}

[is$=-sidenavi] a{
    padding: var(--space-s);
    display: inline-block;
    width: 100%;
}

[is$=-sidenavi] a::before{
    padding-right: var(--space-s);
}

[is$=-sidenavi] a[has~=selection] {
    border-bottom: 1px solid var(--call-to-action-color);
}



[is=sub-navigation] [is=navigation-link]{
    padding: var(--space-s);
    width: 100%;
    display: grid;
    grid-template-columns: var(--space-xl) 1fr var(--space-xl);
}

[is=sub-navigation] [is=navigation-link]::before {
    padding-right: var(--space-s);
    font-size: var(--font-size-ml);
}




[is=sub-navigation] [is=navigation-link]::after {
    --color: var(--foreground-color);

    display: inline-block;
    font: normal normal normal 16px/1 "Line Awesome Free";
    font-weight: 900;
    font-size: var(--font-size-m);
    color: var(--color);
    content: "\f105";
    font-family: 'Line Awesome Free';
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    vertical-align: middle;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    align-self: center;
    justify-self: end;
    
}