

[is=fleeb-details][has~=domain-type] picture {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 40vh;
    background-color: white;
    display: block;
}


domain-detail-view {}
