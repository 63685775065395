body {
    overflow-x: hidden;
}

[has~=standard-layout] {
    display: grid;
    height: 100vh;
    grid-template-rows: 
        calc(var(--header-height) + var(--top-gap)) 
        1fr;
}


@media (max-width: 620px) {
    [has~=standard-layout],
    [has~=mobile-layout] [has~=standard-layout] {
        display: grid;
        height: 100vh;
        grid-template-rows: 
            calc(var(--mobile-header-height) + var(--top-gap)) 
            1fr 
            calc(var(--footer-height) + var(--bottom-gap));
    }
}



[is=container],
[is=fleeb-view]{
    --gap: var(--space-l);
    --columns: 4;
    --columns-width: calc(
        (100vw - 
            (
                var(--gap) * (var(--columns) + 1)
            )
        )  /  var(--columns)
    );

    display: grid;
    gap: var(--gap);
    grid-template-columns: repeat(var(--columns), var(--columns-width));
    justify-content: space-around;
    padding: 0 calc(var(--gap));
    top: calc(var(--size-xl) + var(--size-m) + var(--top-gap));
  /*  position: fixed;
    height: calc(100vh - (var(--size-xl) + var(--size-s)) - var(--top-gap) - var(--bottom-gap));*/
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: black var(--grey-darker-color);
    max-width: 100%;
}

[is=container],
[is=fleeb-view]{
    position: fixed;
    height: calc(100vh - (var(--size-xl) + var(--size-s)) - var(--top-gap) - var(--bottom-gap));
}

@media (max-width: 620px) {
    [is=fleeb-view],
    [has~=mobile-layout] [is=fleeb-view]{
        position: static;
        height: auto;
    }
}

suggest-filter-bar ~ [is=container],
suggest-filter-bar ~ [is=fleeb-view]{
    top: calc(var(--size-xl) + var(--size-m) + var(--top-gap) + var(--size-xl));
}

[is=container] fleebs-loader,
[is=fleeb-view] fleebs-loader {
    grid-column: 1 / calc(var(--columns) + 1);
    overflow: visible;
    padding-top: var(--space-s);
}

@media (max-width: 620px) {

    [is=container],
    [is=fleeb-view],
    [has~=mobile-layout] [is=container],
    [has~=mobile-layout] [is=fleeb-view] {
        --gap: var(--space-m);
        --columns-width: calc( (100vw - ( var(--gap) * (var(--columns) ) ) ) / var(--columns) );
        padding: 0 calc(var(--gap) / 2);
        top: calc(var(--size-xl) + var(--top-gap));
        /* height: calc(100vh - var(--size-xxl) - var(--top-gap) - var(--bottom-gap));
        padding: 0 var(--space-m) 0 var(--space-m); */
    }

    suggest-filter-bar ~ [is=fleeb-view],
    [has~=mobile-layout] suggest-filter-bar ~ [is=fleeb-view] {
        top: calc(var(--size-xxl) + var(--size-m) + var(--top-gap));
    }

    [has~=mobile-layout] [is=container] [is=column],
    [has~=mobile-layout] [is=fleeb-view] [is=column]  {
        margin-top: 0;
    }
}

[is=container] [is=column],
[is=fleeb-view] [is=column] {
    justify-content: space-around;
  /*  margin-top: calc(var(--space-m) * -1);*/
    margin-top: 0;
}




[is=container] [is=column] > *,
[is=fleeb-view] [is=column] > *{
    --margin: calc(var(--gap)) 0;
    margin-bottom: calc(var(--gap));
}


footer {
    position: fixed;
    height: 45px;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: var(--background-color);
    box-shadow: -2px -2px 4px var(--background-color);
    display: none;
}

footer [is=fleebs-button]{
    --size: var(--size-xl);
    --center-position: calc(50% - (var(--size) / 2));
    right: var(--center-position);
    left:  var(--center-position);
    top: calc(var(--size) / 2 * -1);
}


body [has~=mobile-layout] footer{
    display: block;
    height: 60px;
}

[is=overlay]{
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 1;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: none;
}

[has~=overlay] [is=overlay]{
    display: block;
    top: 0;
    left: 0;
    opacity: 0;
    animation-name: overlay-animatiion;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation-fill-mode: forwards;
}

@keyframes overlay-animatiion{
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

[is=content-area][has~=centered-layout],
[is=content-area] {
    padding-top: calc(var(--space-s));
    overflow: auto;
}

@media (max-width: 620px) {
    [is=logo],
    [has~=mobile-layout] [is=logo] {
        text-align: center;
    }

    [is=content-area][has~=centered-layout],
    [is=content-area],
    [has~=mobile-layout] [is=content-area][has~=centered-layout],
    [has~=mobile-layout] [is=content-area] {
        padding:0;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    [is=content-area][has~=centered-layout]::-webkit-scrollbar,
    [is=content-area]::-webkit-scrollbar,
    [has~=mobile-layout] [is=content-area][has~=centered-layout]::-webkit-scrollbar,
    [has~=mobile-layout] [is=content-area]::-webkit-scrollbar {
        display: none;
    }
}





