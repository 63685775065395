bar-loader {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
}

bar-loader [is=bar-loader] {
    background: var(--foreground-color);
    height: var(--size-xxs);
    width: var(--percent);
    transition: height 0.1s ease-in-out;
}

bar-loader [is=bar-loader][now~=hidden] {
    height: 0;
}
