job-details {

}

job-details h4 {
    margin-bottom: var(--space-s);
}

[is=job-properties]{
    margin-bottom: var(--space-s);
}

[is=job-options] {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--space-m);
}


[is=hiring-organization-details][has~=image] {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-gap: var(--space-m);
}

[is=hiring-organization-details][has~=image] img{
    width: 100%;
}

[is=hiring-organization] [is=icon-button] {
    margin-top: var(--space-s);
    display: block;
}

[is=hiring-organization] h4 {
    padding-bottom: var(--space-s);
}

[is=job-bar]{
    padding: var(--space-m);
    padding-bottom: 0;
}

[is=job-organization-part] {
    padding: var(--space-m);
    padding-bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

[is=hiring-organization] {
    padding: var(--space-s);
    border-radius: var(--size-s);
    background-color: var(--background-lighter-color);
    grid-column: 2;
}

